import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./page404.scss";
import { Box } from "@mui/material";

function Page404(props) {
  useEffect(() => {});

  return (
    <Box className="page404-main" sx={{ backgroundColor: "commonBg.bgColor" }}>
      {props.msg ? (
        <Box>
          <Box>{props.msg}</Box>
        </Box>
      ) : (
        <Box className="page404Content">
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/404.gif`}
            alt="page404"
          />
          <Box sx={{ backgroundColor: "tab.bgColor" }} className="btn">
            <Link to="/">Go to Home</Link>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Page404;
