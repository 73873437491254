import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { languages } from "../../App";
import "./AssignTimeTable.scss";
import BackButton from "../Common/BackBtn/BackBtn";
import TimTblStructure from "./TimTblStructure";
import ShowTimeTable from "./ShowTimeTable";

const AssignTimeTable = () => {
  const curLanguage = useContext(languages);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const financialYear = localStorage.getItem("financialYear");
  const userID = userInfo?.[0].id;
  const [apimssg, setApimssg] = useState();
  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classSec, setClassSec] = useState([]);
  const [timetable, setTimetable] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [customPeriods, setCustomPeriods] = useState([
    "1",
    "2",
    "3",
    "Recess",
    "4",
    "5",
    "6",
    "7",
  ]);
  const [customDays, setCustomDays] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ]);

  const [selectedValues, setSelectedValues] = useState({
    selectedClass: "",
    selectedSection: "",
    teacher: "",
    subject: "",
    recess: "",
  });

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/FetchData`,
          {
            record_type: "class_section",
            type: "aerp",
          }
        );
        setClassSec(response.data.records);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchClassData = async (classId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/viewSubjectTeacher`,
        {
          class_id: classId,
          financial_year: financialYear,
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching class data:", error);
      return null;
    }
  };

  const handleClassSelect = async (selectedClassId) => {
    const classData = await fetchClassData(selectedClassId);
    if (classData) {
      setTeachers(classData.teachers);
      setSubjects(classData.subjects);
      setTimetable({});
    }

    setSelectedValues((prevValues) => ({
      ...prevValues,
      selectedClass: selectedClassId,
    }));
  };

  const handleSectionSelect = (selectedSectionId) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      selectedSection: selectedSectionId,
    }));
    setTimetable({});
  };

  const handleSelect = (selectedId, period, day, type) => {
    setTimetable((prevTimetable) => {
      const updatedTimetable = { ...prevTimetable };
      const periodIndex = customPeriods.indexOf(period);
      const dayIndex = customDays.indexOf(day);
      const dayName = day;

      if (!updatedTimetable[dayIndex]) {
        updatedTimetable[dayIndex] = [];
      }

      updatedTimetable[dayIndex][periodIndex] = {
        ...updatedTimetable[dayIndex][periodIndex],
        [type]: selectedId,
        day: dayName,
        period: customPeriods[periodIndex],
      };

      return updatedTimetable;
    });

    setSelectedValues((prevValues) => ({
      ...prevValues,
      [type]: selectedId,
    }));
  };

  const handleCustomPeriodChange = (event, index) => {
    const newCustomPeriods = [...customPeriods];
    newCustomPeriods[index] = event.target.value;
    setCustomPeriods(newCustomPeriods);
  };

  const handleCustomDayChange = (event, index) => {
    const newCustomDays = [...customDays];
    newCustomDays[index] = event.target.value;
    setCustomDays(newCustomDays);
  };

  const addDays = () => {
    setCustomDays((prevCustomDays) => [...prevCustomDays, ""]);
    setTimetable((prevTimetable) => {
      const updatedTimetable = { ...prevTimetable };
      const newDay = customDays[customDays.length];
      updatedTimetable[newDay] = customPeriods.map(() => ({}));
      return updatedTimetable;
    });
  };

  const addPeriods = () => {
    setCustomPeriods((prevCustomPeriods) => [...prevCustomPeriods, ""]);
    setTimetable((prevTimetable) => {
      const updatedTimetable = { ...prevTimetable };
      Object.keys(updatedTimetable).forEach((day) => {
        updatedTimetable[day] = [...updatedTimetable[day], {}];
      });
      return updatedTimetable;
    });
  };

  const removeDays = () => {
    setCustomDays((prevCustomDays) =>
      prevCustomDays.slice(0, prevCustomDays.length - 1)
    );
    setTimetable((prevTimetable) => {
      const updatedTimetable = { ...prevTimetable };
      const removedDay = customDays[customDays.length - 1];
      delete updatedTimetable[removedDay];
      return updatedTimetable;
    });
  };

  const removePeriods = () => {
    setCustomPeriods((prevCustomPeriods) =>
      prevCustomPeriods.slice(0, prevCustomPeriods.length - 1)
    );

    setTimetable((prevTimetable) => {
      const updatedTimetable = { ...prevTimetable };
      Object.keys(updatedTimetable).forEach((day) => {
        updatedTimetable[day] = updatedTimetable[day].slice(
          0,
          customPeriods.length - 1
        );
      });
      return updatedTimetable;
    });
  };

  const moveRecessForward = () => {
    setCustomPeriods((prevCustomPeriods) => {
      const index = prevCustomPeriods.indexOf("Recess");
      if (index < prevCustomPeriods.length - 1) {
        const updatedPeriods = [...prevCustomPeriods];
        // Swap "Recess" with the next period
        [updatedPeriods[index], updatedPeriods[index + 1]] = [
          updatedPeriods[index + 1],
          updatedPeriods[index],
        ];
        return updatedPeriods;
      }
      return prevCustomPeriods;
    });
  };

  const moveRecessBackward = () => {
    setCustomPeriods((prevCustomPeriods) => {
      const index = prevCustomPeriods.indexOf("Recess");
      if (index > 0) {
        const updatedPeriods = [...prevCustomPeriods];
        // Swap "Recess" with the previous period
        [updatedPeriods[index], updatedPeriods[index - 1]] = [
          updatedPeriods[index - 1],
          updatedPeriods[index],
        ];
        return updatedPeriods;
      }
      return prevCustomPeriods;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const flattenedTimetable = customDays.reduce((acc, day) => {
      const dayIndex = customDays.indexOf(day);
      return acc.concat(
        customPeriods
          .map((period, periodIndex) => {
            const entry = timetable[dayIndex]?.[periodIndex];
            return entry ? { ...entry, day, period } : null;
          })
          .filter(Boolean)
      );
    }, []);
    const timeTblUrl = `${process.env.REACT_APP_nodewsPrefix}/saveTimeTable`;
    try {
      const response = await axios.post(timeTblUrl, {
        financial_year: financialYear,
        added_by: userID,
        class: selectedValues.selectedClass,
        section: selectedValues.selectedSection,
        timetable: flattenedTimetable,
      });

      setApimssg(response.data.response);
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <Container maxWidth="xl">
      <Card
        maxWidth="xl"
        sx={{
          p: 0,
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
        className="customCard"
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{ borderColor: "borderLine.main" }}
        >
          <Box className="titleHead">
            <BackButton />
            <b>
              {curLanguage.timeTable}
              <i>
                {selectedValues.selectedClass}
                {selectedValues.selectedSection}
              </i>
            </b>
          </Box>
          <TimTblStructure
            classSec={classSec}
            onSelectClass={handleClassSelect}
            onSelectSection={handleSectionSelect}
            moveRecessBackward={moveRecessBackward}
            moveRecessForward={moveRecessForward}
            addPeriods={addPeriods}
            removePeriods={removePeriods}
            customDays={customDays}
            addDays={addDays}
            removeDays={removeDays}
          />
        </Typography>
        <form onSubmit={handleSubmit}>
          <Card className="timeTblContent" sx={{ py: 2, px: 2 }}>
            <Snackbar
              open={openSnackbar}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <SnackbarContent
                sx={{ backgroundColor: "greenColor.color" }}
                message="Timetable added successfully!"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={handleSnackbarClose}
                  >
                    OK
                  </Button>
                }
              />
            </Snackbar>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="timeTblHead">
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Period</b>
                      </Typography>
                    </TableCell>

                    {customPeriods.map((period, index) => (
                      <TableCell component="th" scope="row" key={index}>
                        <Box display={"flex"} gap={1}>
                          {period === "Recess" ? (
                            ""
                          ) : (
                            <Typography variant="h6">
                              <b>Period</b>
                            </Typography>
                          )}

                          <TextField
                            variant="standard"
                            size="small"
                            value={period}
                            onChange={(event) =>
                              handleCustomPeriodChange(event, index)
                            }
                          />
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customDays.map((day, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <TextField
                          variant="standard"
                          size="small"
                          value={day}
                          onChange={(event) =>
                            handleCustomDayChange(event, index)
                          }
                        />
                      </TableCell>

                      {customPeriods.map((period, periodindex) => (
                        <TableCell
                          align="left"
                          key={`${index}-${periodindex}`}
                          className={`${
                            period === "Recess" ? "recessTr" : "customTr"
                          }`}
                        >
                          {period === "Recess" ? (
                            <FormControl
                              fullWidth
                              sx={{ mb: 1 }}
                              variant="standard"
                              size="small"
                            >
                              <InputLabel>Recess:</InputLabel>
                              <Select
                                onChange={(e) =>
                                  handleSelect(
                                    e.target.value,
                                    period,
                                    day,
                                    "recess"
                                  )
                                }
                                label="Recess"
                              >
                                <MenuItem value="recess">recess</MenuItem>
                              </Select>
                            </FormControl>
                          ) : (
                            <>
                              <FormControl
                                fullWidth
                                sx={{ mb: 1 }}
                                variant="standard"
                                size="small"
                              >
                                <InputLabel>Teacher:</InputLabel>
                                <Select
                                  onChange={(e) =>
                                    handleSelect(
                                      e.target.value,
                                      period,
                                      day,
                                      "teacher"
                                    )
                                  }
                                  label="Teacher"
                                >
                                  {teachers.map((teacher) => (
                                    <MenuItem
                                      key={teacher.user_id}
                                      value={teacher.user_id}
                                    >
                                      {teacher.teacher_name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <FormControl
                                fullWidth
                                variant="standard"
                                size="small"
                              >
                                <InputLabel>Subject:</InputLabel>
                                <Select
                                  onChange={(e) =>
                                    handleSelect(
                                      e.target.value,
                                      period,
                                      day,
                                      "subject"
                                    )
                                  }
                                  label="Subject"
                                >
                                  {subjects.map((subject) => (
                                    <MenuItem
                                      key={subject.id}
                                      value={subject.id}
                                    >
                                      {subject.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box textAlign={"center"} mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="btn"
              >
                Save
              </Button>
            </Box>
          </Card>
        </form>
      </Card>
    </Container>
  );
};

export default AssignTimeTable;
