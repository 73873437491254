import React, { useContext, useState } from "react";
import { Box, Card, Container, Typography, Button, Grid } from "@mui/material";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";
import { languages } from "../../App";
import { Link } from "react-router-dom";
//=====================================

export default function UserManagement() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  console.log(userInfo, "userInfo")
  return (
    <Container maxWidth="xl">
      <Card
        maxWidth="xl"
        sx={{
          p: 0,
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
        className="customCard"
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{ borderColor: "borderLine.main" }}
        >
          <Box className="titleHead">
            <BackButton />
            <b>User Management</b>
          </Box>
        </Typography>
        <Box className="customCardContnet" p={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Link to={{ pathname: "/settings" }} className="btn" style={{ textDecoration: "none" }}>Permission For School</Link>
            </Grid>
            {userInfo[0]?.department === "Developer" && (
              <Grid item>
                <Link to={{ pathname: "/devsettings" }} className="btn" style={{ textDecoration: "none" }}>
                  Set Development Permission
                </Link>
              </Grid>
            )}
          </Grid>
        </Box>
      </Card>
    </Container>
  );
}
