import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Card,
  TextField,
  Button,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import PropTypes from "prop-types";
import "./ExamPlanner.scss";
import BackButton from "../Common/BackBtn/BackBtn";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import OutlinedInput from "@mui/material/OutlinedInput";
import axios from "axios";

import { languages } from "../../App";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DataGrid } from "@mui/x-data-grid";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let defaultFields = {
  eventName: "",
  description: "",
  durationMinutes: "",
  repeat: "",
  endDate: "",
  startDate: "",
  planner: 0,
  class_section_id: "",
  createfor: "",
  subject: "",
};

const ExamPlanner = () => {
  const userinfo = localStorage.getItem("userInfo");
  const userinfoObject = JSON.parse(userinfo);
  const token = localStorage.getItem("token");

  let curLanguage = useContext(languages);

  const [classSec, getClassSec] = useState([]);
  const [examClasses, setExamClasses] = useState([]);
  const [classFullData, setClassFullData] = useState([]);
  const [classSubject, setClassSubject] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [inMinutes, setInMinutes] = useState(false);
  const [examDuration, setExamDuration] = useState("");
  const [createFor, setCreateFor] = useState("");

  const [fieldsValue, setFieldValue] = useState(defaultFields);
  const [eventRow, setEventRow] = useState([]);
  const [examRow, setExamRow] = useState([]);

  const [eventDeleted, setEventDeleted] = useState("");

  const getUpcomingData = () => {
    axios
      .get(
        `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_calendar_get_calendar_upcoming_view_info&moodlewsrestformat=json&categoryid=0`
      )
      .then((response) => {
        // FILTER TO GET ONLY ANNUAL PLANNER
        let filterEvents = response.data.events.filter((data) => {
          return data.planner_type === "1";
        });

        // FILTER TO GET ONLY EXAM PLANNER
        let filterExam = response.data.events.filter((data) => {
          return data.planner_type === "0";
        });

        let calculateDuration = (tDuration) => {
          function secondsToHrsMin(seconds) {
            var hrs = Math.floor(seconds / 3600);
            var min = Math.floor((seconds % 3600) / 60);
            return hrs + " hrs " + min + " min";
          }

          let daysDuration = parseInt(tDuration) / 84600;

          if (daysDuration >= 1) {
            return Math.round(daysDuration) + " Days";
          } else if (Math.round(parseInt(tDuration) / 60) >= 60) {
            return secondsToHrsMin(parseInt(tDuration));
          } else {
            return Math.round(parseInt(tDuration) / 60) + " min";
          }
        };

        let tempExamRow = filterExam.map((data, indx) => {
          return {
            id: data.id,
            subject: data.subject_name,
            date: convertUnixTimestampToDate(data.timestart),
            class: data.class_name,
            duration:
              data.timeduration == 0
                ? "-"
                : calculateDuration(data.timeduration),
            instruction: data.description,
          };
        });

        setExamRow(tempExamRow);

        let tempRow = filterEvents.map((data, indx) => {
          let endDateCustom;

          if (data.timeduration !== 0) {
            endDateCustom =
              parseInt(data.timestart) + parseInt(data.timeduration);
          }

          return {
            id: data.id,
            eventName: data.name,
            description: data.description,
            class: data.classsection,
            dateStart: convertUnixTimestampToDate(data.timestart),
            dateEnd:
              data.timeduration == 0
                ? "-"
                : convertUnixTimestampToDate(endDateCustom),
            duration:
              data.timeduration == 0
                ? "-"
                : calculateDuration(data.timeduration),
            createFor: data.planner_for,
          };
        });
        setEventRow(tempRow);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const deleteEventHandler = (id) => {
    let deleteURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_calendar_delete_calendar_events&moodlewsrestformat=json`;

    id.map((ids, indx) => {
      deleteURL += `&events[${indx}][eventid]=${ids}&events[${indx}][repeat]=1`;
      return null;
    });

    /* axios.get(deleteURL).then(response => {
      setEventDeleted("Event Deleted Successfully")
     
      getUpcomingData();
      setTimeout(() => {
        setEventDeleted("");
      }, 5000);
    }) */
  };

  const editEventHandler = (rowData) => {
    setCreateFor("Class");
    setFieldValue((prevData) => {
      return {
        ...prevData,
        eventName: rowData.eventName,
        description: rowData.description,
        startDate: { date: 28, hour: 0, min: 0, month: 10, year: 2025 },
      };
    });
  };

  const getClasses = () => {
    let userGradeAlotedURL = `${process.env.REACT_APP_wsPrefix}webservice/rest/server.php?wsfunction=core_group_get_course_user_groups_info&moodlewsrestformat=json&wstoken=${token}&courseid=0&userid=0`;

    axios.get(userGradeAlotedURL).then((response) => {
      setClassFullData(response.data);

      let classesToDisplay = response.data.map(
        (classes) => classes.classsection
      );
      getClassSec(classesToDisplay);
    });

    axios
      .get(`${process.env.REACT_APP_nodewsPrefix}/FetchClassSection`)
      .then((response) => {
        const uniqueIds = new Set();
        const uniqueData = response.data.class_section.filter((obj) => {
          if (!uniqueIds.has(obj.class)) {
            uniqueIds.add(obj.class);
            return true;
          }
          return false;
        });

        setExamClasses(uniqueData);
      })
      .catch((err) => {
        console.log("There is some problem in getting classes");
      });
  };

  useEffect(() => {
    getClasses();
  }, []);

  const columns = [
    {
      field: "eventName",
      headerName: "Event Name",
      width: 200,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      width: 280,
      editable: false,
    },
    {
      field: "class",
      headerName: "Class",
      width: 80,
      editable: false,
    },
    {
      field: "dateStart",
      headerName: "Date Start",
      width: 200,
      editable: false,
    },
    {
      field: "dateEnd",
      headerName: "Date End",
      width: 200,
      editable: false,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 100,
      editable: false,
    },

    {
      field: "createFor",
      headerName: "Create for",
      width: 120,
      editable: false,
    },

    {
      field: "delete",
      headerName: "",
      width: 50,
      renderCell: (cellValues) => {
        return (
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/delete.png`}
            className="plannerButton"
            alt="Delete"
            height="50%"
            onClick={() => {
              deleteEventHandler([cellValues.row.id]);
            }}
          />
        );
      },
    },

    /* {
      field: "edit",
      headerName: "",
      width: 50,
      renderCell: (cellValues) => {
        return (
          <img src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/edit.png`} className="plannerButton" alt="Edit" height="50%" onClick={() => { editEventHandler(cellValues.row) }} />
        );
      },
    }, */
  ];

  const columnsExam = [
    {
      field: "subject",
      headerName: "Subject",
      width: 200,
      editable: false,
    },
    {
      field: "date",
      headerName: "Date",
      width: 280,
      editable: false,
    },
    {
      field: "class",
      headerName: "Class",
      width: 80,
      editable: false,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 200,
      editable: false,
    },
    {
      field: "instruction",
      headerName: "Instruction",
      width: 200,
      editable: false,
    },

    {
      field: "delete",
      headerName: "",
      width: 50,
      renderCell: (cellValues) => {
        return (
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/delete.png`}
            className="plannerButton"
            alt="Delete"
            height="50%"
            onClick={() => {
              deleteEventHandler([cellValues.row.id]);
            }}
          />
        );
      },
    },

    /* {
      field: "edit",
      headerName: "",
      width: 50,
      renderCell: (cellValues) => {
        return (
          <img src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/edit.png`} className="plannerButton" alt="Edit" height="50%" onClick={() => { editEventHandler(cellValues.row) }} />
        );
      },
    }, */
  ];

  function convertUnixTimestampToDate(timestamp) {
    // Multiply by 1000 to convert to milliseconds (Unix timestamps are in seconds)
    let date = new Date(timestamp * 1000);

    // Format date
    let formattedDate = date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    // Format time
    let formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    return `${formattedDate}, ${formattedTime}`;
  }

  useEffect(() => {
    getUpcomingData();
  }, []);

  let names = classSec;

  const handleChangeCl = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    setFieldValue((prevVal) => {
      return { ...prevVal, class_section_id: event.target.value };
    });
  };

  const handleChangeCreateFor = (event) => {
    setCreateFor(event.target.value);
    setFieldValue((prevVal) => {
      return { ...prevVal, createfor: event.target.value };
    });
  };

  const handleChangeEventName = (event) => {
    setFieldValue((prevVal) => {
      return { ...prevVal, eventName: event.target.value };
    });
  };

  const handleChangeStartDates = (e) => {
    setFieldValue((prevVal) => {
      return {
        ...prevVal,
        startDate: {
          date: e.$D,
          month: e.$M + 1,
          year: e.$y,
          hour: e.$H,
          min: e.$m,
        },
      };
    });
  };

  const handleChangeEndDates = (e) => {
    setFieldValue((prevVal) => {
      return {
        ...prevVal,
        endDate: {
          date: e.$D,
          month: e.$M + 1,
          year: e.$y,
          hour: e.$H,
          min: e.$m,
        },
      };
    });
  };

  const handleChangeDescription = (e) => {
    setFieldValue((prevVal) => {
      return { ...prevVal, description: e.target.value };
    });
  };

  const handleChangeMinutes = (e) => {
    setFieldValue((prevVal) => {
      return { ...prevVal, durationMinutes: e.target.value };
    });
  };

  const handleChangeRepeat = (e) => {
    setFieldValue((prevVal) => {
      return { ...prevVal, repeat: e.target.value };
    });
  };

  const [value, setValue] = React.useState(0);
  const [withoutDuration, setWithoutDuration] = useState(true);

  const [repeatEvent, setRepeatEvent] = useState(false);
  const [validationErr, setValidationErr] = useState("");
  const [eventPosted, setEventPosted] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    selectExamClass("");
    setCreateFor("");
    setValidationErr("");
    setFieldValue(defaultFields);
    setFieldValue((prevState) => {
      return { ...prevState, planner: newValue };
    });
    setWithoutDuration(true);
    setRepeatEvent(false);
    setPersonName([]);
  };

  const durationHandler = (e) => {
    if (e.target.value === "Withoutduration") {
      setWithoutDuration(true);
      setInMinutes(false);
    } else if (e.target.value === "until") {
      setWithoutDuration(false);
      setInMinutes(false);
    } else {
      setWithoutDuration(true);
      setInMinutes(true);
    }
  };

  const repeatEventHandler = (e) => {
    setRepeatEvent(e.target.checked);
  };

  const resetFields = (pla) => {
    setCreateFor("");
    setValidationErr("");
    setFieldValue({
      eventName: "",
      description: "",
      durationMinutes: "",
      repeat: "",
      endDate: "",
      startDate: "",
      planner: pla,
      class_section_id: pla ? "" : 1,
      createfor: "",
      subject: "",
    });
    setWithoutDuration(true);
    setRepeatEvent(false);
    setPersonName([]);
    setSubjectSelected("");
    setExamDuration("");
    selectExamClass("");
  };

  const plannerSubmitHandler = async (e) => {
    e.preventDefault();
    var numbered = /^[0-9]+$/;

    // POSTING WITH VALIDATION

    if (
      fieldsValue.createfor === "Class" &&
      fieldsValue.class_section_id.length === 0
    ) {
      setValidationErr("Class can not be empty");
    } else if (fieldsValue.startDate === "") {
      setValidationErr("Date can not be empty");
    } else if (value === 0 && fieldsValue.class_section_id === "") {
      setValidationErr("Class can not be empty");
    } else if (
      value === 0 &&
      classSubject.length > 1 &&
      fieldsValue.subject === ""
    ) {
      setValidationErr("Subject can not be empty");
    } else if (!withoutDuration && fieldsValue.endDate === "") {
      setValidationErr("End Date can not be empty");
    } else if (
      inMinutes &&
      !fieldsValue.durationMinutes.match(numbered) &&
      fieldsValue === ""
    ) {
      setValidationErr("Duration must be number");
    } else if (
      repeatEvent &&
      fieldsValue.repeat === "" &&
      !fieldsValue.repeat.match(numbered)
    ) {
      setValidationErr("Repeat can not be empty or string");
    } else {
      const timeStampURLStart = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_calendar_get_timestamps&moodlewsrestformat=json&data[0][year]=${fieldsValue.startDate.year}&data[0][month]=${fieldsValue.startDate.month}&data[0][day]=${fieldsValue.startDate.date}&data[0][hour]=${fieldsValue.startDate.hour}&data[0][minute]=${fieldsValue.startDate.min}`;

      const resp = await axios.get(timeStampURLStart);
      const timestamp = resp.data.timestamps[0].timestamp;

      if (fieldsValue.planner) {
        let groupId = [];
        let courseId = [];

        if (fieldsValue.class_section_id && fieldsValue.createfor === "Class") {
          fieldsValue.class_section_id.map((data) => {
            let tempData = classFullData.filter((fullData) => {
              return fullData.classsection === data;
            });

            groupId.push(tempData[0].id);
            courseId.push(tempData[0].courseid);
            return null;
          });
        }

        let timestampEnd;

        if (fieldsValue.endDate !== "") {
          const timeStampURLEnd = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_calendar_get_timestamps&moodlewsrestformat=json&data[0][year]=${fieldsValue.endDate.year}&data[0][month]=${fieldsValue.endDate.month}&data[0][day]=${fieldsValue.endDate.date}&data[0][hour]=${fieldsValue.endDate.hour}&data[0][minute]=${fieldsValue.endDate.min}`;

          const respEnd = await axios.get(timeStampURLEnd);
          timestampEnd = respEnd.data.timestamps[0].timestamp;
        }

        let createEventURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_calendar_create_calendar_events&moodlewsrestformat=json`;

        let plannerFor;
        let etype;

        if (fieldsValue.createfor === "Class") {
          plannerFor = "c";
          etype = "group";
        } else if (fieldsValue.createfor === "Teacher") {
          plannerFor = "t";
          etype = "user";
        } else {
          plannerFor = "s";
          etype = "user";
        }

        let classSelected = fieldsValue.class_section_id.length;

        let timeDuration = 0;
        if (fieldsValue.durationMinutes !== "") {
          timeDuration = fieldsValue.durationMinutes * 60;
        }

        if (timestampEnd) {
          timeDuration = timestampEnd - timestamp;
        }

        if (fieldsValue.createfor === "Class") {
          for (var ci = 0; ci < classSelected; ci++) {
            createEventURL += `&events[${ci}][name]=${
              fieldsValue.eventName
            }&events[${ci}][description]=${
              fieldsValue.description
            }&events[${ci}][repeats]=${
              fieldsValue.repeat === "" ? 0 : fieldsValue.repeat
            }&events[${ci}][timestart]=${timestamp}&events[${ci}][groupid]=${
              groupId[ci]
            }&events[${ci}][courseid]=${
              courseId[ci]
            }&events[${ci}][timeduration]=${
              timeDuration === "" ? 0 : timeDuration
            }&events[${ci}][eventtype] = ${etype}`;
          }
        } else {
          // for school and teacher
          createEventURL += `&events[0][name]=${
            fieldsValue.eventName
          }&events[0][description]=${
            fieldsValue.description
          }&events[0][repeats]=${
            fieldsValue.repeat === "" ? 0 : fieldsValue.repeat
          }&events[0][timestart]=${timestamp}&events[0][groupid]=0&events[0][courseid]=0&events[0][timeduration]=${
            timeDuration === "" ? 0 : timeDuration
          }&events[0][eventtype] = ${etype}`;
        }

        axios
          .get(createEventURL)
          .then((response) => {
            let eventsId = response.data.events.map((data) => {
              return data.id;
            });

            axios
              .post(`${process.env.REACT_APP_nodewsPrefix}/addPlannerDetail/`, {
                event: eventsId,
                planner_type: 1,
                planner_for: plannerFor,
                added_by: userinfoObject[0]?.id,
              })
              .then((response) => {
                getUpcomingData();
                resetFields(1);
              });

            setEventPosted("Event Added Successfully.");

            setTimeout(() => {
              setEventPosted("");
            }, 5000);
          })
          .catch((err) => {
            console.log(err);
            setEventPosted("Something went wrong please try again");
          });
      } else {
        // EXAM EVENT POSTING
        let timeDuration = 0;
        if (fieldsValue.durationMinutes !== "") {
          timeDuration = fieldsValue.durationMinutes * 60;
        }

        let createExamURL = `${
          process.env.REACT_APP_wsPrefix
        }/webservice/rest/server.php?wstoken=${token}&wsfunction=core_calendar_create_calendar_events&moodlewsrestformat=json&events[0][name]=${
          fieldsValue.eventName
        }&events[0][description]=${
          fieldsValue.description
        }&events[0][repeats]=${0}&events[0][timestart]=${timestamp}&events[0][groupid]=0&events[0][courseid]=${
          fieldsValue.subject[0].course_id
        }&events[0][timeduration]=${timeDuration === "" ? 0 : timeDuration}`;

        axios.get(createExamURL).then((response) => {
          let postParams = {
            event: [response.data.events[0].id],
            planner_type: 0,
            class_subject_id: fieldsValue.subject[0]?.class_subjectid,
            added_by: userinfoObject[0]?.id,
          };

          axios
            .post(
              `${process.env.REACT_APP_nodewsPrefix}/addPlannerDetail`,
              postParams
            )
            .then((response) => {
              setEventPosted("Exam posted successfully");
              setTimeout(() => {
                setEventPosted("");
              }, 5000);
              getUpcomingData();
              resetFields(0);
            });
        });
      }
    }
  };

  const [examClass, selectExamClass] = useState("");

  const selectExamClassHandler = (event) => {
    selectExamClass(event.target.value);
    setFieldValue((prevVal) => {
      return { ...prevVal, class_section_id: event.target.value };
    });

    axios
      .post(`${process.env.REACT_APP_nodewsPrefix}/getClassSubjects`, {
        classname: event.target.value,
      })
      .then((response) => {
        setClassSubject(response.data.subject_data[0]);
      });
  };

  const [subjectSelected, setSubjectSelected] = useState("");

  const selectSubjectHandler = (event) => {
    let selectedSubData = classSubject.filter((allSubject) => {
      return allSubject.subjectname === event.target.value;
    });

    setSubjectSelected(event.target.value);
    setFieldValue((prevState) => {
      return { ...prevState, subject: selectedSubData };
    });
  };

  const examDurationHandler = (e) => {
    setExamDuration(e.target.value);
    setFieldValue((prevVal) => {
      return { ...prevVal, durationMinutes: e.target.value };
    });
  };

  const createForm = (formFor) => {
    return (
      <Grid container className="examFields">
        <Grid item xs={12} md={12}>
          {validationErr !== "" && <Box>{validationErr}</Box>}
          {eventPosted !== "" && <Box>{eventPosted}</Box>}
        </Grid>

        {formFor === "annual" && (
          <Grid item xs={12} md={12}>
            <InputLabel id="CreatingFor">Create For</InputLabel>
            <Select
              labelId="CreatingFor"
              id="demo-simple-select"
              value={createFor}
              label="Creating For"
              onChange={handleChangeCreateFor}
              className="plannerCreated"
            >
              <MenuItem value={"School"}>School</MenuItem>
              <MenuItem value={"Teacher"}>Teacher</MenuItem>
              <MenuItem value={"Class"}>Class</MenuItem>
            </Select>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <TextField
            id="eventName"
            value={fieldsValue.eventName}
            label={formFor === "annual" ? "Event Name" : "Exam Name"}
            className={
              formFor === "annual"
                ? "plannerEventName"
                : "plannerEventName exam"
            }
            onChange={handleChangeEventName}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <DateTimePicker
                label={curLanguage.eventDate}
                disablePast
                required
                value={fieldsValue.startDate}
                className="plannerStartDate"
                onChange={handleChangeStartDates}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>

        {formFor === "annual" && createFor === "Class" && (
          <>
            <Grid item xs={12} md={3}>
              <InputLabel id="SelectClass">Class</InputLabel>
              <Select
                labelId="SelectClass"
                id="SelectClass"
                multiple
                value={personName}
                onChange={handleChangeCl}
                input={<OutlinedInput label="Select Class" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                sx={{ minWidth: "150px", width: "96%" }}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item md={9} xs={12} className="customTxt">
              <TextField
                id="outlined-multiline-flexible"
                label={curLanguage.description}
                multiline
                name="description"
                className="plannerDescription withClassDesc"
                onChange={handleChangeDescription}
                value={fieldsValue.description}
                required
              />
            </Grid>
          </>
        )}

        {formFor === "annual" &&
          (createFor === "School" || createFor === "Teacher") && (
            <Grid item md={12} xs={12} className="customTxt">
              <TextField
                id="outlined-multiline-flexible"
                label={curLanguage.description}
                multiline
                name="description"
                className="plannerDescription"
                onChange={handleChangeDescription}
                value={fieldsValue.description}
              />
            </Grid>
          )}

        {formFor === "annual" && (
          <>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Withoutduration"
              name="radio-buttons-group"
              className="plannerRadio"
            >
              <Grid container>
                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    value="Withoutduration"
                    control={<Radio />}
                    label="Without duration"
                    onChange={durationHandler}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    value="until"
                    control={<Radio />}
                    label="Until"
                    onChange={durationHandler}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    value="minutes"
                    control={<Radio />}
                    label="Duration in minutes"
                    onChange={durationHandler}
                  />
                </Grid>
              </Grid>
            </RadioGroup>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    label={curLanguage.eventDate}
                    value={fieldsValue.endDate}
                    disabled={withoutDuration}
                    className="plannerEndDate"
                    onChange={handleChangeEndDates}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-multiline-flexible"
                label={curLanguage.minutes}
                multiline
                maxRows={1}
                name="Minutes"
                disabled={!inMinutes}
                className="plannerMinute"
                onChange={handleChangeMinutes}
                value={fieldsValue.durationMinutes}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={<Checkbox onChange={repeatEventHandler} />}
                label={curLanguage.repeatThisEvent}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                id="plannerRepeat"
                label={curLanguage.repeatWeeklyEvent}
                multiline
                maxRows={1}
                name="repeatCount"
                disabled={!repeatEvent}
                required={repeatEvent}
                className="plannerRepeat"
                onChange={handleChangeRepeat}
              />
            </Grid>
          </>
        )}

        {formFor === "exam" && (
          <>
            <Grid item xs={12} md={3}>
              <InputLabel id="examClasses">Class</InputLabel>
              <Select
                labelId="examClasses"
                id="examClasses"
                value={examClass}
                label="Select Class"
                onChange={selectExamClassHandler}
                className="examClasses"
                MenuProps={MenuProps}
              >
                {examClasses.map((classData, indx) => {
                  return (
                    <MenuItem key={indx} value={classData.class}>
                      {classData.class}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            {classSubject.length > 0 && (
              <Grid item xs={12} md={3}>
                <InputLabel id="examSubject">Subject</InputLabel>
                <Select
                  labelId="examSubject"
                  id="examSubject"
                  value={subjectSelected}
                  label="Subject"
                  onChange={selectSubjectHandler}
                  className="examClasses"
                  MenuProps={MenuProps}
                >
                  {classSubject.map((subjectData) => {
                    return (
                      <MenuItem value={subjectData.subjectname}>
                        {subjectData.subjectname}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                id="examDuration"
                label="Duration in Minutes"
                className="examDuration"
                onChange={examDurationHandler}
                value={examDuration}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="outlined-multiline-flexible"
                label={curLanguage.description}
                multiline
                name="description"
                className="plannerDescription withClassDesc"
                onChange={handleChangeDescription}
                value={fieldsValue.description}
                required
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Button
            type="submit"
            color="popupbutton"
            className={formFor === "exam" ? "btns2 examP" : "btns2"}
            variant="contained"
            sx={{
              backgroundColor: "secondaryColor.main",
              color: "white",
            }}
          >
            {curLanguage.save}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  return (
    <Container maxWidth="xl">
      <form onSubmit={plannerSubmitHandler}>
        <Box className="examWrapper examPl">
          <Card
            maxWidth="xl"
            sx={{
              mb: 4,
              backgroundColor: "cardColor.main",
            }}
            elevation={5}
            className="customCard"
          >
            <Typography
              className="title"
              variant="h5"
              px={2}
              py={1}
              sx={{ borderColor: "borderLine.main" }}
            >
              <Box className="titleHead">
                <BackButton />
                <b>Annual Planner</b>
              </Box>
            </Typography>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="iconColor.color"
              textColor="inherit"
              variant="fullWidth"
              sx={{
                backgroundColor: "tab.bgColor",
                "& .Mui-selected": {
                  backgroundColor: "iconColor.color",
                  fontWeight: 700,
                  letterSpacing: "0.5px",
                  color: "#fff",
                  fontSize: "16px",
                },
              }}
            >
              <Tab label="Exam Planner" {...a11yProps(0)} />
              <Tab label="Event Planner" {...a11yProps(1)} />
            </Tabs>

            <CustomTabPanel value={value} index={0}>
              {createForm("exam")}
              <Box className="upcomingEvents">
                <Typography variant="h3">
                  <Box>Exams List</Box>
                  {rowSelectionModel.length > 0 && (
                    <Box className="deleteAll">
                      Delete Selected - {rowSelectionModel.length}
                    </Box>
                  )}
                </Typography>

                <Box>{eventDeleted}</Box>
                <DataGrid
                  rows={examRow}
                  columns={columnsExam}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25]}
                  checkboxSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                />
              </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              {createForm("annual")}
              <Box className="upcomingEvents">
                <Typography variant="h3">
                  <Box>Upcoming Events</Box>
                </Typography>

                <Box>{eventDeleted}</Box>

                <DataGrid
                  rows={eventRow}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Box>
            </CustomTabPanel>
          </Card>
        </Box>
      </form>
    </Container>
  );
};

export default ExamPlanner;
