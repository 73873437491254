import React, { useEffect, useState } from "react";
import { AccordionSummary, Box, Typography } from "@mui/material";
import ChapterCompletion from "./ChapterCompletion";
import axios from "axios";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";

const AccordionChildItem = ({ valChild, courseID, classid, activityDetails }) => {
  const { permissions } = usePermissions();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const financialYear = localStorage.getItem("financialYear");
  const userID = userInfo?.[0].id;
  const [syllabusCheck, setSyllabusCheck] = useState();
  const [teacherCompletion, setTeacherCompletion] = useState();

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/viewCourseSectionCompletion`,
          {
            courseid: courseID,
            classid: classid,
            teacherid: userID,
            financial_year: financialYear,
          }
        );
        setSyllabusCheck(response?.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getData();

    if (userInfo?.[0].department.toLowerCase() === "student") {
      const stdtData = async () => {
        try {
          let response = await axios.post(
            `${process.env.REACT_APP_nodewsPrefix}/viewCourseSectionCompletion`,
            {
              courseid: courseID,
              classid: classid,
              studentid: userID,
              financial_year: financialYear,
            }
          );
          setTeacherCompletion(response?.data.data);
        } catch (err) {
          console.log(err);
        }
      };

      stdtData();
    }
  }, [courseID, userID, financialYear, valChild.id]);

  const isCourseCompleted = syllabusCheck?.some(
    (data) => data.course_sectionid == valChild.id && data.status === 1
  );

  const isTeachercompleted = teacherCompletion?.some(
    (data) => data.course_sectionid == valChild.id && data.status === 1
  );
  const validChapter = valChild?.summary.includes("Chapter:");

  return (
    <AccordionSummary className="accordionSumm innerAccordionHEad">
      <Typography>
        <b>{valChild.name}</b>
        {/* {activityDetails.includes(valChild.name) ? "dddd" : ""} */}
      </Typography>
      {validChapter && (
        <>
          {isTeachercompleted && (
            <Box
              style={{
                position: "absolute",
                right: 30,
                top: 6,
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Typography>
                <small>Completed By Teacher</small>
              </Typography>
              <img
                src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/approved.png`}
                alt="checkBox"
                title="Chapter Completed By Teacher"
                width={35}
              />
            </Box>
          )}
          {permissions.markChapter?.write && permissions.markChapter?.read && (
            <ChapterCompletion
              courseID={courseID}
              valChild={valChild}
              classid={classid}
              isCourseCompleted={isCourseCompleted}
            />
          )}
        </>
      )}
    </AccordionSummary>
  );
};

export default AccordionChildItem;
