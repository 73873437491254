import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import Parser from "html-react-parser";
import "./getCalendarEvent.css";
import "./getCalendarEvent.scss";
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { languages } from "../../../App";
import IconWithText from "../../../_Components/Utils/IconWithText/IconWithText";
import DescriptionIcon from "@mui/icons-material/Description";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

function GetCalendarEvent(props) {
  const [calendarEvent, setCalendarEvent] = useState({});
  const [saveBtn, setSaveBtn] = useState(false);
  const [editedData, setEditedData] = useState({
    date: "",
    userName: "",
    description: "",
  });
  const { id } = props;
  let curLanguage = useContext(languages);
  const baseURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${props.token}&wsfunction=core_calendar_get_calendar_event_by_id&moodlewsrestformat=json&eventid=${id}`;

  console.log(baseURL);
  const moduleNames = ["scorm", "quiz"];

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setCalendarEvent(response.data);
      console.log(response.data, "vlaiewuirh");
    });
  }, [id]);

  const parseHTML = (data) => {
    if (data == "") return "";
    let abc = <div dangerouslySetInnerHTML={{ __html: data }} />;
    return abc;
  };

  //Edited Data
  const handleContentChange = (field, updatedValue) => {
    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      [field]: updatedValue,
    }));
  };

  const saveData = () => {
    props.isVisible(false);
    props.setConfirm({ isShow: false, eventCount: null });
  };

  const handleDelete = () => {
    props.setConfirm({
      isShow: true,
      eventCount: calendarEvent.event.eventcount,
    });
  };

  const handleSlideshow = () => {
    alert("work in progress");
  };
  const handleSaveBtn = () => {
    setSaveBtn(true);
  };
  const multipleDelete = (e) => {
    let value = e.target.getAttribute("id");
    let deleteURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${props.token}&wsfunction=core_calendar_delete_calendar_events&moodlewsrestformat=json&events[0][eventid]=${id}&events[0][repeat]=${value}`;

    axios.get(deleteURL).then((response) => {
      props.isVisible(false);
      props.setConfirm({ isShow: false, eventCount: null });
    });
  };

  const dateTime = (dataTime) => {
    const date = new Date(dataTime * 1000);
    return date.toDateString();
  };

  const closePopup = () => {
    props.isVisible(false);
    props.setConfirm({ isShow: false, eventCount: null });
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      // color: theme.palette.tooltip.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.tooltip.backgroundColor,
      color: theme.palette.tooltip.main,
      border: "1px solid white",
    },
  }));

  return (
    <>
      {!props.confirm.isShow ? (
        <Box className="" onClick={(e) => e.stopPropagation()}>
          {calendarEvent.event ? (
            <>
              <DialogTitle
                className="title"
                sx={{
                  backgroundColor: "popup.titleHeaderColor",
                  color: "popup.popupheadertextcolor",
                }}
              >
                <Typography variant="h6">{calendarEvent.event.name}</Typography>
                <BootstrapTooltip title="Close">
                  <Button
                    sx={{
                      backgroundColor: "secondaryColor.main",
                      color: "white",
                    }}
                    className="popupCloseButton"
                    onClick={closePopup}
                    color="popupbutton"
                    variant="contained"
                  >
                    {" "}
                    <CloseIcon />
                  </Button>
                </BootstrapTooltip>
              </DialogTitle>
              <DialogContent
                sx={{
                  backgroundColor: "cardColor.main",
                  color: "cardColor.contrast",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Box className="modalContent">
                      <IconWithText tag="Schedule" bg={false} />
                      <Box>
                        <Typography variant="h6">{curLanguage.date}</Typography>
                        <p
                          contentEditable
                          onInput={(event) =>
                            handleContentChange(
                              "date",
                              event.target.textContent
                            )
                          }
                        >
                          {dateTime(calendarEvent.event.timesort)}
                        </p>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box className="modalContent">
                      <IconWithText tag="calendar" bg={false} />
                      <Box>
                        <Typography variant="h6">{curLanguage.userName}</Typography>
                        <p
                          contentEditable
                          onInput={(event) =>
                            handleContentChange(
                              "userName",
                              event.target.textContent
                            )
                          }
                        >
                          {calendarEvent.event.eventtype} event
                        </p>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <Box className="modalContent">
                      <IconWithText tag="description" bg={false} />
                      <Box>
                        <Typography variant="h6">{curLanguage.description} </Typography>
                        <p
                          contentEditable
                          onInput={(event) =>
                            handleContentChange(
                              "description",
                              event.target.textContent
                            )
                          }
                        >
                          {parseHTML(calendarEvent.event.description)}
                        </p>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              {calendarEvent.event.candelete ? (
                <DialogActions
                  sx={{
                    borderColor: "secondaryColorOutline.border",
                    backgroundColor: "cardColor.main",
                  }}
                >
                  {/* <Button
                      variant="contained"
                      color="popupbutton"
                      sx={{
                        backgroundColor: "secondaryColor.main",
                        color: "white",
                      }}
                      className="secOutBtn btns2"
                      onClick={handleSaveBtn}
                    >
                      {curLanguage.edit}
                    </Button> */}
                  <Button
                    variant="contained"
                    className="btns2"
                    sx={{
                      backgroundColor: "secondaryColorOutline.main",
                      color: "secondaryColorOutline.contrastText",
                    }}
                    onClick={handleDelete}
                  >
                    {curLanguage.delete}
                  </Button>
                  {saveBtn && (
                    <Button
                      variant="contained"
                      color="popupbutton"
                      sx={{
                        backgroundColor: "secondaryColor.main",
                        color: "white",
                      }}
                      className="secOutBtn btns2"
                      onClick={saveData}
                    >
                      {curLanguage.save}
                    </Button>
                  )}

                  {moduleNames.indexOf(calendarEvent.event.modulename) > -1 ? (
                    <Button
                      className="deleteEvent"
                      color="popupbutton"
                      variant="contained"
                      onClick={handleSlideshow}
                    >
                      START
                    </Button>
                  ) : (
                    ""
                  )}
                </DialogActions>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}

      {props.confirm.isShow ? (
        <Box className="eventWrapper" onClick={(e) => e.stopPropagation()}>
          {calendarEvent.event ? (
            <>
              <DialogTitle
                className="title"
                sx={{
                  backgroundColor: "popup.titleHeaderColor",
                  color: "popup.popupheadertextcolor",
                }}
              >
                <h3>{calendarEvent.event.name}</h3>
                <Button
                  className="popupCloseButton"
                  onClick={closePopup}
                  variant="contained"
                  sx={{ minWidth: 10 }}
                >
                  <CloseIcon />{" "}
                </Button>
              </DialogTitle>
              <DialogContent
                sx={{
                  backgroundColor: "cardColor.main",
                  color: "cardColor.contrast",
                }}
              >
                <Box className="confirm">{curLanguage.areYouSure}</Box>
              </DialogContent>

              <DialogActions
                sx={{
                  borderColor: "secondaryColorOutline.border",
                  backgroundColor: "cardColor.main",
                }}
              >
                <Button
                  variant="contained"
                  className="btns2"
                  sx={{
                    backgroundColor: "secondaryColor.main",
                    color: "white",
                  }}
                  id="0"
                  onClick={multipleDelete}
                >
                  {curLanguage.delete}
                </Button>
                {props.confirm.eventCount > 0 ? (
                  <Button
                    className="secOutBtn btns2"
                    variant="contained"
                    sx={{
                      backgroundColor: "secondaryColorOutline.main",
                      color: "secondaryColorOutline.contrastText",
                    }}
                    id="1"
                    onClick={multipleDelete}
                  >
                    {curLanguage.deleteAllEvent}
                  </Button>
                ) : (
                  ""
                )}
              </DialogActions>
            </>
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
export default GetCalendarEvent;
