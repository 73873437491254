export default function ProcessText(text) {
  const lastUnderscoreIndex = text.lastIndexOf('_');
  const numberAfterUnderscore = text.slice(lastUnderscoreIndex + 1);
  const number = parseInt(numberAfterUnderscore); 
  const extractedText = text.slice(lastUnderscoreIndex + 2).trim();
  
  return {
    number: number,
    text: extractedText
  };
}

