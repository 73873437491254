import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../Buttons/CustomBtns";

const PrincipalFilter = ({
  forDepartment,
  forClass,
  forSection,
  forSubject,
  forStudent,
  onselectionchangeData,
}) => {
  const [classData, setClassData] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedDepatment, setSelectedDepatment] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const token = localStorage.getItem("token");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection`;
    axios.get(baseURL).then((response) => {
      setClassData(response.data.class_section);
      console.log(response.data.class_section, "class and section");
    });
  }, []);

  const handleSelectionChange = (event, selectionType) => {
    const value = event.target.value;
    if (selectionType === "class") {
      setSelectedClass(value);
    } else if (selectionType === "section") {
      setSelectedSection(value);
    } else if (selectionType === "subject") {
      setSelectedSubject(value);
      fetchData(selectedClass, selectedSection, value);
    } else if (selectionType === "department") {
      setSelectedDepatment(value);
    } else if (selectionType === "student") {
      setSelectedStudent(value);
    }
  };

  const handleFilterData = () => {
    onselectionchangeData(
      selectedClass,
      selectedSection,
      selectedSubject,
      selectedDepatment,
      selectedStudent
    );
  };

  const handleRefreshData = () => {
    setSelectedClass("");
    setSelectedSection("");
    setSelectedSubject("");
    setSelectedDepatment("");
    setSelectedStudent("");
  };

  const fetchData = async (
    selectedClassValue,
    selectedSectionValue,
    selectedSubjectValue
  ) => {
    try {
      const enroledURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_enrolled_users_info&moodlewsrestformat=json&courseid=${selectedSubjectValue}`;
      console.log(enroledURL, "enroledURL");
      const response2 = await axios.get(enroledURL);
      const studentData = response2.data.filter(
        (val) =>
          val.department.toLowerCase() === "student" &&
          val.section === selectedSectionValue &&
          val.class == selectedClassValue &&
          val.institution === userinfo[0].institution
      );
      setStudentInfo(studentData);
    } catch (error) {
      console.error("Error fetching Student Info", error);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {forDepartment && (
          <Grid item md={2}>
            <FormControl fullWidth>
              <InputLabel id="department-select-department">
                Department
              </InputLabel>
              <Select
                labelId="department-select-department"
                id="department-select"
                value={selectedDepatment}
                input={<OutlinedInput label="Department" />}
                onChange={(event) => handleSelectionChange(event, "department")}
              >
                <MenuItem value="Pedagogy">Teacher</MenuItem>
                <MenuItem value="student">Student</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {selectedDepatment === "student" && (
          <>
            {forClass && (
              <Grid item md={2}>
                <FormControl fullWidth>
                  <InputLabel id="class-select-label">Class</InputLabel>
                  <Select
                    labelId="class-select-label"
                    id="class-select"
                    value={selectedClass}
                    input={<OutlinedInput label="Class" />}
                    onChange={(event) => handleSelectionChange(event, "class")}
                  >
                    {classData.length > 0 &&
                      Array.from(
                        new Set(
                          classData
                            .map((obj) => obj.class)
                            .sort((a, b) => a - b)
                        )
                      ).map((data, index) => (
                        <MenuItem key={index} value={data}>
                          Class {data}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {forSection && (
              <Grid item md={2}>
                <FormControl fullWidth>
                  <InputLabel id="section-select-label">Section</InputLabel>
                  <Select
                    labelId="section-select-label"
                    id="section-select"
                    value={selectedSection}
                    input={<OutlinedInput label="Section" />}
                    onChange={(event) =>
                      handleSelectionChange(event, "section")
                    }
                  >
                    {classData.length > 0 &&
                      Array.from(
                        new Set(
                          classData
                            .map((obj) => obj.section)
                            .sort((a, b) => a - b)
                        )
                      ).map((data, index) => (
                        <MenuItem key={index} value={data}>
                          Section {data}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {forSubject && (
              <Grid item md={3}>
                <FormControl fullWidth>
                  <InputLabel id="subject-select-label">Subject</InputLabel>
                  <Select
                    labelId="subject-select-label"
                    id="subject-select"
                    value={selectedSubject}
                    input={<OutlinedInput label="Section" />}
                    onChange={(event) =>
                      handleSelectionChange(event, "subject")
                    }
                  >
                    <MenuItem value="171"> English 1</MenuItem>
                    <MenuItem value="178"> English 2</MenuItem>
                    <MenuItem value="173"> English 3</MenuItem>
                    <MenuItem value="177"> Environmental Studies 1</MenuItem>
                    <MenuItem value="180"> Environmental Studies 2</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {forStudent && (
              <Grid item md={3}>
                <FormControl fullWidth>
                  <InputLabel id="section-select-label">Student</InputLabel>
                  <Select
                    labelId="section-select-label"
                    id="section-select"
                    value={selectedStudent}
                    input={<OutlinedInput label="Student" />}
                    onChange={(event) =>
                      handleSelectionChange(event, "student")
                    }
                  >
                    {studentInfo.map((name) => (
                      <MenuItem key={name.id} value={name.fullname}>
                        {name.fullname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Box mt={3} display={"flex"} justifyContent="center" gap={2}>
        <PrimaryBgBtn type="submit" onClick={handleFilterData}>
          Save
        </PrimaryBgBtn>
        <PrimaryOutlineBtn onClick={handleRefreshData}>
          Refresh
        </PrimaryOutlineBtn>
      </Box>
    </Box>
  );
};

export default PrincipalFilter;
