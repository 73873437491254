import { Box, MenuItem, MenuList, } from "@mui/material";
import styles from "./QuickLinks.module.scss";
import QuickLinkItem from "./QuickLinkItem";
import axios from "axios";
import { useEffect, useState } from "react";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";
import { useLocation } from "react-router-dom";

const QuickLinks = (props) => {
  const location = useLocation();
  const { permissions } = usePermissions();
  const userInfoData = localStorage.getItem("userInfo");
  const userInfo = JSON.parse(userInfoData);
  const course = localStorage.getItem("courseDetails");
  const courserInfo = JSON.parse(course);
  const [isAdmin, setIsAdmin] = useState(false);
  const [assigncount, setAssignCount] = useState([]);
  const [viewAssessment, setviewAssessment] = useState([]);
  const [classInfo, setClassInfo] = useState(5);

  const isDashboardScreen = location.pathname === "/dashboard";

  useEffect(() => {
    if (userInfo) {
      setIsAdmin(userInfo[0]?.department === "admin");
    }
    getUserClass();
  }, []);

  const getUserClass = async () => {
    // let resp = await axios.post(
    //   `${process.env.REACT_APP_nodewsPrefix}/getUserInfo`,
    //   {
    //     username: userInfo[0]?.username,
    //   }
    // );
    // localStorage.setItem("classInfo", JSON.stringify(resp.data));
    // setClassInfo(resp.data);

    if (permissions.classes?.write) {
      const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByTeacherCourse`;
      axios
        .post(v_assessmentURL, {
          userid: userInfo[0].id,
        })
        .then((response) => {
          console.log(response.data, "NNO");
          setAssignCount(response.data.data);
          setviewAssessment(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    if (permissions.courses?.write) {
      const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByStudentCourse`;
      axios
        .post(v_assessmentURL, {
          userid: userInfo[0]?.id,
          courseid: courserInfo[0]?.id,
          classid: JSON.parse(localStorage.getItem("classInfo"))?.classid,
        })
        .then((response) => {
          setAssignCount(response.data.data);
          setviewAssessment(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <Box className={styles.quickLinks}>
      <MenuList
        autoFocusItem={props.autoFocusItem}
        onKeyDown={props.onKeyDown}
        className={styles.lists}
      >
        {(permissions.dashboard?.read || permissions.dashboard?.write) && (
          <MenuItem onClick={props.handleClose}>
            {isDashboardScreen ? (
              <QuickLinkItem
                text="Home"
                icon="home.png"
                url="/"
                permissionName="dashboard"
              />
            ) : (
              <QuickLinkItem
                text="Dashboard"
                icon="dashboard.png"
                url="/dashboard"
                permissionName="dashboard"
              />
            )}
          </MenuItem>
        )}
        {(permissions.assignment?.read || permissions.assignment?.write) && (
          <MenuItem onClick={props.handleClose}>
            <QuickLinkItem
              text="Assignments"
              icon="assignment.png"
              url="/Assignments"
              permissionName="assignment"
              assignmentCounts={assigncount?.length}
              viewAssessment={viewAssessment}
            />
          </MenuItem>
        )}
        {(permissions.attendance?.read || permissions.attendance?.write) && (
          <MenuItem onClick={props.handleClose}>
            <QuickLinkItem
              text="Attendance"
              icon="attendance.png"
              url="/attendance"
              permissionName="attendance"
            />
          </MenuItem>
        )}
        {(permissions.calendar?.read || permissions.calendar?.write) && (
          <MenuItem onClick={props.handleClose}>
            <QuickLinkItem
              text="Calendar"
              icon="calendar.png"
              url="/calendar"
              permissionName="calendar"
            />
          </MenuItem>
        )}
        {(permissions.certificate?.read || permissions.certificate?.write) && (
          <MenuItem onClick={props.handleClose}>
            <QuickLinkItem
              text="Certificate"
              icon="certificate.png"
              {...(permissions.certificate?.read &&
                permissions.certificate?.write && { url: "/add-certificate" })}
              {...(permissions.certificate?.read &&
                !permissions.certificate?.write && { url: "/stdcertificate" })}
              permissionName="certificate"
            />
          </MenuItem>
        )}
        {(permissions.dateSheet?.read || permissions.dateSheet?.write) && (
          <MenuItem onClick={props.handleClose}>
            <QuickLinkItem
              text="Date Sheet"
              icon="datesheet.png"
              url="/dateSheet"
              permissionName="dateSheet"
            />
          </MenuItem>
        )}
        {(permissions.notes?.read || permissions.notes?.write) && (
          <MenuItem onClick={props.handleClose}>
            <QuickLinkItem
              text="Notes"
              icon="notes.png"
              url="/notes"
              permissionName="notes"
            />
          </MenuItem>
        )}
        {(permissions.report?.read || permissions.report?.write) && (
          <MenuItem onClick={props.handleClose}>
            <QuickLinkItem
              text="Report Card"
              icon="report.png"
              url="/report"
              permissionName="report"
            />
          </MenuItem>
        )}
        {(permissions.timetable?.read || permissions.timetable?.write) && (
          <MenuItem onClick={props.handleClose}>
            <QuickLinkItem
              text="Time Table"
              icon="timetable.png"
              {...(permissions.timetable?.read &&
                permissions.timetable?.write && { url: "/teacherTimeTable" })}
              {...(permissions.timetable?.read &&
                !permissions.timetable?.write && { url: "/studentTimeTable" })}
              permissionName="timetable"
            />
          </MenuItem>
        )}
      </MenuList>
    </Box>
  );
}

export default QuickLinks;
