import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { usePermissions } from "../Permission/PermissionsContext";

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

const ProtectedRoute = ({ element, requiredPermissions }) => {
  const navigate = useNavigate();
  const nToken = localStorage.getItem("nToken");
  const { permissions } = usePermissions();
  try {
    var user = JSON.parse(localStorage.getItem("userInfo"));
    var department = user?.[0].department.toLowerCase();
  } catch (error) {
    localStorage.clear();
    console.log("User info not found in Local Storage.");
    window.location.reload();
    navigate("/");
  }

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/isauthorised`,
          {
            headers: { Authorization: `Bearer ${nToken}` },
          }
        );

        if (!response.data.success) {
          localStorage.clear();
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking authorization:", error);
      }
    };
    try {
      if (isAuthenticated()) {
        if (
          department !== "developer" &&
          permissions[requiredPermissions]?.read
        ) {
          checkAuthorization();
        } else if (department === "developer") {
          checkAuthorization();
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      localStorage.clear();
      navigate("/");
      window.location.reload();
    }
  }, [nToken, navigate, permissions, requiredPermissions, department]);

  if (
    isAuthenticated() &&
    (department === "developer" || permissions[requiredPermissions]?.read)
  ) {
    return element;
  } else if (department) {
    return <Navigate to="/Unauthorized" />;
  } else {
    return <Navigate to="/Unauthorized" />;
  }
};

export default ProtectedRoute;
