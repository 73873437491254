import axios from "axios";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import "./ForgotPassword.scss";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

const UserForgotPassword = () => {
  const [username, setUserName] = useState([]);

  const setUserData = (e) => {
    setUserName(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = { user: username };
    axios
      .post(
        `${process.env.REACT_APP_wsPrefix}customwebservice/user/password_mail.php?wsfunction=user_mail`,
        data
      )
      .then((res) => {
        alert("Mail sent for the password reset");
        console.log(res);
      })
      .catch((err) => console.error(err));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card className="PasswordDetails">
        <TextField
          className="passwordFields"
          id="username"
          label="Username or Email"
          type="text"
          name="password"
          color="warning"
          onChange={setUserData}
          fullWidth
        />
        <Button
          variant="contained"
          color="warning"
          textAlign={"center"}
          fullWidth
          type="submit"
        >
          Submit
        </Button>

        <Box textAlign={"center"} pt={2}>
          <Typography
            variant="text"
            textAlign={"center"}
            sx={{ color: "linkTxt.color" }}
          >
            Go to{" "}
            <Link sx={{ color: "linkTxt.color" }} className="homeLink" to="/">
              Home
            </Link>
          </Typography>
        </Box>
      </Card>
    </form>
  );
};

export default UserForgotPassword;
