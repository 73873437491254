import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./page404.scss";
import { Box } from "@mui/material";

function Unauthorized(props) {
  useEffect(() => {});

  return (
    <Box className="page404-main" sx={{ backgroundColor: "commonBg.bgColor" }}>
      <Box className="page404Content">
        <img src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/404.gif`} alt="Unauthorized.gif" />
        <p>You don't have permision to access this page.</p>
        <Box sx={{ backgroundColor: "tab.bgColor" }} className="btn">
          <Link to="/">Go to Home</Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Unauthorized;
