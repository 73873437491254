import { Box, Card, Container, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const UserReportData = ({
  filterCourseID,
  filterCoursenames,
  filterClass,
  filterSection,
  filterStudent,
}) => {
  const [courseData, setCourseData] = useState(true);
  const [courseUsers, setCourseUsers] = useState([]);
  const [progressData, setProgressData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reportDataURL = `${process.env.REACT_APP_nodewsPrefix}/getActivityCompletion?course_id=${filterCourseID}&class=${filterClass}&section=${filterSection}&userid=${filterStudent}`;
        const response = await axios.get(reportDataURL);
        
        // Update courseData and courseUsers
        setCourseData(response.data.course_module);
        setCourseUsers(response.data.course_user);
  
        // Filter and process data based on selected filters
        const getActivityName = (id) => {
          let courseName;
          response.data.course_module.forEach((courseData) => {
            courseData.id.forEach((activityId) => {
              if (parseInt(activityId) === id) {
                courseName = courseData.name;
              }
            });
          });
          return courseName;
        };
  
        const studentFullData = response.data.course_user.map((studentData) => {
          let studentActivities = studentData.attendId.map((studentAttemptID) => {
            return getActivityName(studentAttemptID);
          });
  
          const counts = studentActivities.reduce((acc, curr) => {
            acc[curr] = (acc[curr] || 0) + 1;
            return acc;
          }, {});
  
          const result = Object.entries(counts).map(([key, value]) => ({
            activity: key,
            count: value,
          }));
  
          return { ...studentData, count: result };
        });
  
        // Update progressData with filtered and processed data
        setProgressData(studentFullData);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [filterCourseID, filterClass, filterSection, filterStudent]);
  
  const studentClickHandler = (id, studentName) => {
    navigate({
      pathname: "/GetCourseContent/StudentReport",
      search: `id=${id}&studentName=${studentName}&courseid=${filterCourseID}&coursename=${filterCoursenames}&stdclass=${filterClass}&section=${filterSection}`,
    });
    console.log(id, studentName);
  };
  return (
    <Container maxWidth="xl">
      {console.log(courseUsers, "progressData")}
      <Card
        sx={{
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
          position: "relative",
          marginTop: "16px",
          padding: "25px 15px",
        }}
        elevation={5}
        className="customCard"
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
          justifyContent={"center"}
        >
          {courseUsers.map((userData) => (
            <Box
              key={userData.id}
              className="cardBox"
              maxWidth={"250px"}
              minWidth={"230px"}
              onClick={() =>
                studentClickHandler(
                  userData.id,
                  `${userData.firstname} ${userData.lastname}`
                )
              }
            >
              <Typography>
                {userData.firstname}&nbsp;{userData.lastname}
              </Typography>
              <Typography>{userData.course_name}</Typography>
              <Typography>
                {userData.class}&nbsp;{userData.section}
              </Typography>
            </Box>
          ))}
        </Box>
      </Card>
    </Container>
  );
};

export default UserReportData;
