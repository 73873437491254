import { Box } from "@mui/material"
import { Link } from "react-router-dom"
import styles from "./footer.module.scss";

const FooterSocialMedia = () => {
    return(
        <Box
        className={styles["social-media"]}
        mt={2}
        display={"flex"}
        gap={1}
      >
        <Link>
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/facebook.svg`}
            width={35}
            alt="facebook"
          />
        </Link>
        <Link>
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/instagram.svg`}
            width={35}
            alt="instagram"
          />
        </Link>
        <Link>
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/x.svg`}
            width={35}
            alt="x"
            style={{ filter: "invert(1)" }}
          />
        </Link>
        <Link>
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/youtube.svg`}
            width={35}
            alt="youtube"
          />
        </Link>
        <Link>
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/linkedin.svg`}
            width={35}
            alt="linkedin"
          />
        </Link>
      </Box>
    )
}

export default FooterSocialMedia