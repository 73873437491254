import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Typography } from "@mui/material";
import logo from "../../images/logo.png";
import Loader from "../../_Components/Utils/loader/Loader";
import "./login.scss";
import { usePermissions } from "../Permission/PermissionsContext";

function Login(props) {
  const [user, setUser] = useState({ username: "", password: "" });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfos, setUserInfos] = useState({});
  const navigate = useNavigate();
  const { setPermissions } = usePermissions();

  const onLoginClicked = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const tokenURL = `${process.env.REACT_APP_wsPrefix}/login/token.php?username=${user.username}&password=${user.password}&service=ws`;
    axios
      .get(tokenURL)
      .then((response) => {
        if (response.data.token !== undefined) {
          localStorage.setItem("token", response.data.token);
          authorisation(user.username, user.password, response.data.token);
        } else {
          setIsLoading(false);
          setError(response.data.error);
        }
      })
      .catch(function (error) {
        alert(error.message);
      });
  };

  const authorisation = async (uname, pass, token) => {
    let data = { uname, pass };
    let response = await axios.post(
      `${process.env.REACT_APP_nodewsPrefix}/login`,
      data
    );
    let custresponse = await axios.get(
      `${process.env.REACT_APP_wsPrefix}/customloginsso/index.php?token-genrate=1&username=${uname}&password=${pass}`
    );

    localStorage.setItem("nToken", response.data);
    localStorage.setItem("mToken", custresponse.data.token);
    fetchUser(token);
  };

  const fetchUser = async (token) => {
    // Fetch user site info
    const siteInfoUrl = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_webservice_get_site_info&moodlewsrestformat=json`;
    const siteInfoResponse = await axios.get(siteInfoUrl);
    const userId = siteInfoResponse.data.userid;
    const usernames = siteInfoResponse.data.username;

    // Fetch user details
    const userInfoUrl = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_user_get_users_by_field&moodlewsrestformat=json&field=id&values[0]=${userId}`;
    const userInfoResponse = await axios.get(userInfoUrl);
    const userData = userInfoResponse.data;
    setUserInfos(userData);
    localStorage.setItem("userInfo", JSON.stringify(userData));

    // Fetch course details
    const courseInfoUrl = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_users_courses&moodlewsrestformat=json&userid=${userId}`;
    const courseInfoResponse = await axios.get(courseInfoUrl);
    const courseData = courseInfoResponse.data;
    localStorage.setItem("courseDetails", JSON.stringify(courseData));

    //Set Financial Year
    const financialYearUrl = await axios.post(
      `${process.env.REACT_APP_nodewsPrefix}/FetchData`,
      {
        record_type: "financial_year",
        type: "aerp",
      }
    );
    const financialYear = financialYearUrl.data.records;
    const financeyear = financialYear.filter(
      (filterData) => filterData.status === 1
    );
    if (financeyear.length > 0) {
      const idToStore = financeyear[0].id;
      localStorage.setItem("financialYear", idToStore.toString());
    } else {
      console.error("No financial year with status 1 found");
    }

    //set class and sec
    let resp = await axios.post(
      `${process.env.REACT_APP_nodewsPrefix}/getUserInfo`,
      {
        username: usernames,
      }
    );
    localStorage.setItem("classInfo", JSON.stringify(resp.data));

    navigate("/dashboard", { state: { token } });
    window.location.reload();
  };

  const handleChange = (e) => {
    setError(null);
    setIsLoading(false);
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const forgotHandler = () => {
    window.location.href = "/user-forgot-password";
  };

  return (
    <Box className="loginForm" onClick={(e) => e.stopPropagation()}>
      <Box className="loginLogo" textAlign={"center"}>
        <img src={logo} alt="Ratna Sagar logo" />
      </Box>

      <Box className="loginMssg">
        {error != null ? (
          <Typography sx={{ color: "red" }}>{error}</Typography>
        ) : (
          ""
        )}
      </Box>

      <form onSubmit={onLoginClicked}>
        <Box className="loginDetails">
          <TextField
            className="loginFields"
            id="username"
            label="Name"
            type="text"
            name="username"
            onChange={handleChange}
            color="warning"
            fullWidth
          />
          <TextField
            className="loginFields"
            id="userpassword"
            label="Password"
            type="password"
            name="password"
            onChange={handleChange}
            color="warning"
            fullWidth
          />
        </Box>
        <Button
          disabled={isLoading}
          className={isLoading ? "loaderArea" : ""}
          type="submit"
          sx={{ py: 1.5 }}
          variant="contained"
          color="warning"
          textAlign={"center"}
          fullWidth
        >
          {isLoading ? <Loader /> : "Login"}
        </Button>
        <Box textAlign={"center"} pt={2}>
          {
            <Button
              variant="text"
              textAlign={"center"}
              sx={{ color: "linkTxt.color" }}
              onClick={forgotHandler}
            >
              Forgot Password?
            </Button>
          }
        </Box>
      </form>
    </Box>
  );
}

export default Login;
