import React from "react";
import { useRoutes } from "react-router-dom";
import MainLayout from "../../_Layout/MainLayout";
import Home from "../../_pages/Home/Home";
import Notes from "../../_pages/Notes/Notes";
import Dashboard from "../../_pages/Dashboard/Dashboard";
import Attendance from "../../_pages/Attendance/Attendance";
import ProtectedRoute from "./ProtectedRoute";
import Assignments from "../../_pages/ViewAssessment/Assignments";
import GetCourseContent from "../../_pages/GetCourseContent/GetCourseContent";
import ModAccessInformation from "../../_pages/Modules/ModAccessInformation";
import AddCertificate from "../../_pages/Certificate/AddCertificate";
import AdmissionForm from "../../_pages/AdmissionForm/AdmissionForm";
import FeeCollection from "../../_pages/Fees/FeeCollection";
import Calendar from "../../_pages/calendar/Calendar";
import UserProfileDetails from "../../_pages/userProfile/UserProfileDetails";
import Doubt from "../../_pages/Doubts/Doubt";
import Page404 from "../../_pages/page404/Page404";
import Unauthorized from "../../_pages/page404/Unauthorized";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import UserForgotPassword from "../ForgotPassword/UserForgotPassword";
import AssessmentAttempt from "../../_pages/assessmentAttempt/assessmentAttempt";
import AssignTimeTable from "../TimeTable/AssignTimeTable";
import StudentTimeTable from "../TimeTable/StudentTimeTable";
import TeacherTimeTable from "../TimeTable/TeacherTimeTable";
import QuizAttemptSummary from "../../_pages/Quiz/QuizAttemptSummary";
import ExamPlanner from "../ExamPlanner/ExamPlanner";
import DateSheet from "../ExamPlanner/DateSheet";
import SettingsPage from "./SettingPage";
import QuizStudentList from "../../_pages/Quiz/QuizStudentList";
import QuizAttemptReview from "../../_pages/Quiz/QuizAttemptReview";
import StdCertificates from "../../_pages/Certificates_Awards/StdCertificates";
import DevSettingsPage from "./DevSettingsPage";
import UserManagement from "../../_pages/UserManagement/UserManagement";
import Assignment from "../../_pages/ViewAssessment/Assignment";
import ReportCard from "../../_pages/Reports/ReportCard";
import StudentProgressDetail from "../../_pages/ProgressReport/StudentProgressDetail";
import StudentProgress from "../../_pages/ProgressReport/StudentProgress";
import UserReports from "../../_pages/PrincipalUser/Reports/UserReports";
import Logs from "../Logs/Logs";
import StaffAttendance from "../../_pages/PrincipalUser/AdminAttendance/StaffAttendance";
import UserCertificate from "../../_pages/PrincipalUser/Certificate/UserCertificate";

export const Router = (props) => {
  const routes = useRoutes([
    {
      element: <MainLayout />,
      children: [
        { path: "/", element: <Home /> },
        {
          path: "/devsettings",
          element: <ProtectedRoute element={<DevSettingsPage />} />,
        },
        {
          path: "/settings",
          element: <SettingsPage />,
        },
        {
          path: "/logs",
          element: <Logs/>,
        },
        {
          path: "/dashboard",
          element: (
            <ProtectedRoute
              element={<Dashboard />}
              requiredPermissions="dashboard"
            />
          ),
        },
        {
          path: "/notes",
          element: (
            <ProtectedRoute element={<Notes />} requiredPermissions="notes" />
          ),
        },
        {
          path: "/attendance",
          element: (
            <ProtectedRoute
              element={<Attendance />}
              requiredPermissions="attendance"
            />
          ),
        },
        {
          path: "/userManagement",
          element: (
            <ProtectedRoute
              element={<UserManagement />}
              requiredPermissions="adminStaff"
            />
          ),
        },

        // {
        //   path: "/studentAttendance",
        //   element: (
        //     <ProtectedRoute
        //       element={<StudentAttendance />}
        //       requiredPermissions="studentAttendance"
        //     />
        //   ),
        // },
        {
          path: "/assignments",
          element: (
            <ProtectedRoute
              element={<Assignments />}
              requiredPermissions="assignment"
            />
          ),
        },

        {
          path: "/assignment",
          element: (
            <ProtectedRoute
              element={<Assignment />}
              requiredPermissions="assignment"
            />
          ),
        },

        {
          path: "/report",
          element: (
            <ProtectedRoute
              element={<ReportCard />}
              requiredPermissions="report"
            />
          ),
        },
        {
          path: "/GetCourseContent",
          element: (
            <ProtectedRoute
              element={<GetCourseContent />}
              requiredPermissions="GetCourseContent"
            />
          ),
        },
        
        {
          path: "/GetCourseContent/allStudentsReport",
          element: (
            <ProtectedRoute
              element={<StudentProgressDetail />}
              requiredPermissions="GetCourseContent"
            />
          ),
        },
        {
          path: "/GetCourseContent/StudentReport",
          element: (
            <ProtectedRoute
              element={<StudentProgress />}
              requiredPermissions="GetCourseContent"
            />
          ),
        },
        {
          path: "/add-certificate",
          element: (
            <ProtectedRoute
              element={<AddCertificate />}
              requiredPermissions="AddCertificate"
            />
          ),
        },
        {
          path: "/stdcertificate",
          element: (
            <ProtectedRoute
              element={<StdCertificates />}
              requiredPermissions="stdcertificate"
            />
          ),
        },
        {
          path: "GetCourseContent/modAccessInformation/quizstudentlist/quizattemptreview",
          element: (
            <ProtectedRoute
              element={<QuizAttemptReview />}
              requiredPermissions="attemptReview"
            />
          ),
        },
        {
          path: "/calendar",
          element: (
            <ProtectedRoute
              element={<Calendar />}
              requiredPermissions="calendar"
            />
          ),
        },
        {
          path: "/profile",
          element: (
            <ProtectedRoute
              element={<UserProfileDetails />}
              requiredPermissions="profile"
            />
          ),
        },
       
        {
          path: "/doubt",
          element: (
            <ProtectedRoute element={<Doubt />} requiredPermissions="doubt" />
          ),
        },
        {
          path: "/forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "/user-forgot-password",
          element: <UserForgotPassword />,
        },
        {
          path: "/assessmentAttempt",
          element: (
            <ProtectedRoute
              element={<AssessmentAttempt />}
              requiredPermissions="assessmentattempt"
            />
          ),
        },
        {
          path: "/assignTimeTable",
          element: (
            <ProtectedRoute
              element={<AssignTimeTable />}
              requiredPermissions="assigntimeTable"
            />
          ),
        },

        {
          path: "/userManagement",
          element: (
            <ProtectedRoute
              element={<UserManagement />}
              requiredPermissions="userManagement"
            />
          ),
        },
        {
          path: "/studentTimeTable",
          element: (
            <ProtectedRoute
              element={<StudentTimeTable />}
              requiredPermissions="stdtimeTable"
            />
          ),
        },
        {
          path: "/teacherTimeTable",
          element: (
            <ProtectedRoute
              element={<TeacherTimeTable />}
              requiredPermissions="teachertimeTable"
            />
          ),
        },
        {
          path: "/dateSheet",
          element: (
            <ProtectedRoute
              element={<DateSheet />}
              requiredPermissions="dateSheet"
            />
          ),
        },
        {
          path: "/examplanner",
          element: (
            <ProtectedRoute
              element={<ExamPlanner />}
              requiredPermissions="examplanner"
            />
          ),
        },

        {
          path: "/admissionForm",
          element: (
            <ProtectedRoute
              element={<AdmissionForm />}
              requiredPermissions="admission"
            />
          ),
        },
        {
          path: "/feeCollection",
          element: (
            <ProtectedRoute
              element={<FeeCollection />}
              requiredPermissions="feeCollection"
            />
          ),
        },

        {
          path: "AssessmentAttempt/attemptSummary",
          element: (
            <ProtectedRoute
              element={<QuizAttemptSummary />}
              requiredPermissions="attemptSummary"
            />
          ),
        },

        {
          path: "/GetCourseContent/modAccessInformation",
          element: (
            <ProtectedRoute
              element={<ModAccessInformation />}
              requiredPermissions="studentQuiz"
            />
          ),
        },
        {
          path: "GetCourseContent/modAccessInformation/quizstudentlist",
          element: (
            <ProtectedRoute
              element={<QuizStudentList />}
              requiredPermissions="studentQuiz"
            />
          ),
        },

        //Admin and principal user router
        {
          path: "/adminAttendance",
          element: (
            <ProtectedRoute
              element={<StaffAttendance />}
              requiredPermissions="attendance"
            />
          ),
        },
        {
          path: "/user-reports",
          element: (
            <ProtectedRoute
              element={<UserReports />}
              requiredPermissions="attendance"
            />
          ),
        },
        {
          path: "/user-certificate",
          element: (
            <ProtectedRoute
              element={<UserCertificate />}
              requiredPermissions="attendance"
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <Page404 />,
    },
    {
      path: "/Unauthorized",
      element: <Unauthorized />,
    },
  ]);
  return routes;
};
