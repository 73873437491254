import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import "./recentCourse.scss";
import { useLocation } from "react-router";
import {
  Box,  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import Loader from "../../_Components/Utils/loader/Loader";
import { languages } from "../../App";
import CardPrimary from "../../_Components/Common/Cards/CardPrimary";

function MyCourse(props) {
  let curLanguage = useContext(languages);
  const location = useLocation();
  const token = localStorage.getItem("token");
  const course = JSON.parse(localStorage.getItem("courseDetails"));
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  let classInfo = props.classInfo;
  const [isLoading, setIsLoading] = useState(true);
  // const baseURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_course_get_recent_courses&moodlewsrestformat=json`;

  useEffect(() => {
    /* axios.get(baseURL).then((response) => {
      setCourse(response.data);
    }); */

    setIsLoading(false);
  }, []);

  
  let coursesDiv = course?.map((item, ind) => {
    return (
      <NavLink
        key={ind}
        to={{
          pathname: "/GetCourseContent",
          search: `?id=${item.id}&fullname=${item.fullname}&classid=${userinfo[0]?.customfields?.[0].value}`
        }}
        className="navLinks"
        // state={{
        //   id: item.id,
        //   fullname: item.fullname,
        //   classid: userinfo[0]?.customfields?.[0].value,
        //   breadcrumbId: item.id,
        // }}
      >
        <Box className="imgSec">
          <img
            src={item.overviewfiles[0]?.fileurl? item.overviewfiles[0]?.fileurl?.replace("/webservice", "") : `${process.env.REACT_APP_wsPrefix}lms-assets/images/demo_course_icon.jpg`}
            alt="course-img"
          />
        </Box>
        <Box className="textArea">
          <Typography
            className="courseName"
            variant="h4"
            sx={{ color: "recentSub.main" }}
          >
            {item.fullname}
          </Typography>
        </Box>
        <Box className="overlay">
          <Box className="overlayTxt">
            <Typography className="subject" style={{ fontSize: "20px" }}>
              {" "}
              {item.fullname}
            </Typography>
          </Box>
        </Box>
      </NavLink>
    );
  });
  return (
    <CardPrimary curLanguage={curLanguage.mycourses}>
      {isLoading ? (
        <Loader />
      ) : coursesDiv && coursesDiv.length > 0 ? (
        coursesDiv
      ) : (
        <Box className="noCourse" sx={{ color: "red" }}>
          {curLanguage.norecentcourses}
        </Box>
      )}
    </CardPrimary>
  );
}
export default MyCourse;
