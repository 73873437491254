import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import { IoTrashOutline } from "react-icons/io5";
import "./SettingPage.scss";
import BackButton from "../Common/BackBtn/BackBtn";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Scrollbar } from "react-scrollbars-custom";

const SettingsPage = () => {
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getConfigData`
        );
        setPermissions(response.data.info);
        console.log(response.data.info);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchData();
  }, []);

  const handlePermissionChange = (role, section, permissionType) => {
    setPermissions((prevPermissions) => {
      const newPermissions = {
        ...prevPermissions,
        [role]: {
          ...prevPermissions[role],
          [section]: {
            ...prevPermissions[role][section],
            [permissionType]: !prevPermissions[role][section][permissionType],
          },
        },
      };
      return newPermissions;
    });
  };

  const handleFormData = async (e) => {
    e.preventDefault();
    const datas = JSON.stringify(permissions);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_nodewsPrefix}/updateConfigData?data=${datas}`
      );
      console.log("Updated successfully:", response.data);
      alert("Updated Successfully");
    } catch (error) {
      console.error("Error updating permissions:", error);
    }
  };

  return (
    <Container maxWidth="xl">
      <Card
        maxWidth="xl"
        sx={{
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
        className="customCard setCard"
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{
            borderColor: "borderLine.main",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box className="titleHead">
            <BackButton />
            <b>Settings Page</b>
          </Box>
        </Typography>
        {!permissions ? (
          <Box>Loading...</Box>
        ) : (
          <Box className="customCardContent settingCard" p={2}>
            <form onSubmit={handleFormData}>
              <CardContent className="cardContent">
              <Scrollbar
                  style={{ width: "100%", height: 600 }}
                  className="customCardItem"
                >
                  {Object.keys(permissions).map((role, index) => (
                    <Accordion key={role} className="customAccordion">
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Typography
                          variant="h6"
                          sx={{ textTransform: "capitalize" }}
                        >
                          <b>{role === "pedagogy" ? "Teacher" : role}</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          {console.log(permissions, "permissions")}
                          {Object.keys(permissions[role]).map((section) => (
                            <Grid key={section} item md={3} sm={6} xs={6}>
                              <Box key={section} className="componentArea">
                                <Typography
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {section}
                                </Typography>
                                <Box display={"flex"} alignItems={"center"}>
                                  <label>
                                    Read:&nbsp;
                                    <input
                                      type="checkbox"
                                      checked={permissions[role][section].read}
                                      onChange={() =>
                                        handlePermissionChange(
                                          role,
                                          section,
                                          "read"
                                        )
                                      }
                                    />
                                    &nbsp;&nbsp;
                                  </label>
                                  <label>
                                    Write:&nbsp;
                                    <input
                                      type="checkbox"
                                      checked={permissions[role][section].write}
                                      onChange={() =>
                                        handlePermissionChange(
                                          role,
                                          section,
                                          "write"
                                        )
                                      }
                                    />
                                  </label>
                                  <label>
                                        CanAccess:&nbsp;
                                        <input
                                          type="checkbox"
                                          checked={
                                            permissions[role][section].canAccess
                                          }
                                          onChange={() =>
                                            handlePermissionChange(
                                              role,
                                              section,
                                              "canAccess"
                                            )
                                          }
                                        />
                                      </label>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Scrollbar>
              </CardContent>
              <CardActions className="settingSave">
                <Box textAlign={"center"} width={"100%"}>
                  <Button type="submit" variant="contained" className="btn">
                    Save
                  </Button>
                </Box>
              </CardActions>
            </form>
          </Box>
        )}
      </Card>
    </Container>
  );
};

export default SettingsPage;
