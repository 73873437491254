import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Typography,
} from "@mui/material";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import axios from "axios";

const ChapterCompletion = ({
  onCompletionChange,
  courseID,
  valChild,
  classid,
  isCourseCompleted,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const financialYear = localStorage.getItem("financialYear");
  const userID = userInfo?.[0].id;
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [initialChecked, setInitialChecked] = useState(false);
  const checkPageCondition = checked || isCourseCompleted

  useEffect(() => {
    setInitialChecked(checked);
  }, [checked]);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const numericValue = isChecked ? 1 : 0;
    setChecked(isChecked);
    console.log(
      {
        courseid: courseID,
        classid: classid,
        course_sectionid: valChild.id,
        userid: userID,
        status: numericValue,
        financial_year: financialYear,
      },
      "Value of select"
    );

    axios
      .post(
        `${process.env.REACT_APP_nodewsPrefix}/addCourseSectionCompletion`,
        {
          courseid: courseID,
          classid: classid,
          course_sectionid: valChild.id,
          userid: userID,
          status: numericValue,
          financial_year: financialYear,
        }
      )
      .then((response) => {
        console.log("Data saved successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleResetCheckbox = () => {
    setChecked(initialChecked);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        style={{
          position: "absolute",
          right: 30,
          top: 6,
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {checkPageCondition ? (
          <>
            <Typography>
              <small>Chapter Completed</small>
            </Typography>
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/checkinner.png`}
              alt="checkBox"
              width={25}
            />
          </>
        ) : (
          <>
            <Typography>
              <small>Mark Chapter as Completed</small>
            </Typography>
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/checkouter.png`}
              alt="checkBox"
              width={25}
            />
          </>
        )}
      </Button>
      <CustomDialog
        isOpen={open}
        onClose={handleClose}
        className="syllabusCheck"
        width="xs"
        disableBackdropOption={false}
        onEnter={handleResetCheckbox}
      >
        <DialogContent sx={{ textAlign: "center" }}>
          <Typography variant="h5">
            <b>Mark this chapter as {checkPageCondition ? "uncompleted" : "completed" }?</b>
          </Typography>
          <Box className="completionBox">
            <FormControlLabel
              className={checkPageCondition ? "checkboxContainer blueBtn" : "checkboxContainer greenBtn" }
              onClick={handleClose}
              control={
                <Checkbox checked={checked} onChange={handleCheckboxChange} />
              }
              label="Yes"
            />
            <Button onClick={handleClose} className="btn closeRedBtn">
              No
            </Button>
          </Box>
        </DialogContent>
      </CustomDialog>
    </>
  );
};

export default ChapterCompletion;
