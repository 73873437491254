import { Box, Button, Card, TextField, Typography } from "@mui/material";
import "./ForgotPassword.scss";
import { Link,useSearchParams  } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

const ForgotPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [password,setPasswords] = useState([])
  const [confirmpassword,setconfirmPasswords] = useState([])

  const setPassword = (e) =>{
    // console.log(e.target.value)
    setPasswords(e.target.value)
  }

  const setConfirmPassword = (e) =>{
    // console.log(e.target.value)
    setconfirmPasswords(e.target.value)
  }


  const handleSubmit = (event)=>{
    event.preventDefault()
    let token = searchParams.get('token')
    let data = { 'newpassword':password,'confirmpassword':confirmpassword}
    if(password != confirmpassword){
      alert('New and confirm password are not same')
    }else{
      axios
      .post(`${process.env.REACT_APP_wsPrefix}customwebservice/user/fpassword.php?token=${token}&wsfunction=forgot_passwordchange`,
      data)
      .then((res) => {
        alert(res.data.success_msg)
        if(res.data.success){
          setTimeout(()=>{
            // window.location.href = "/";
          },1000)
        }
      })
      .catch((err) => console.error(err)) 
    }
  }

  

  return (
    <form onSubmit={handleSubmit}>
      <Card className="PasswordDetails">
        <TextField
          className="passwordFields"
          id="username"
          label="Password"
          type="password"
          name="password"
          color="warning"
          onChange={setPassword}
          fullWidth
        />
        <TextField
          className="passwordFields"
          id="userpassword"
          label="Re-Enter Password"
          type="password"
          name="password"
          color="warning"
          onChange={setConfirmPassword}
          fullWidth
        />
        <Button
          variant="text"
          textAlign={"center"}
          fullWidth
          type="submit"
        >
          Save
        </Button>
        <Box textAlign={"center"} pt={2}>
          <Typography variant="p">Go to <Link  sx={{ color: "linkTxt.color" }} to = "/" >Home</Link></Typography>
        </Box>
      </Card>
    </form>
  );
};

export default ForgotPassword;
