import { Box, Grid, Typography } from "@mui/material";
import MainCard from "../../_Components/Common/Cards/MainCard";
import { useLocation } from "react-router-dom";
import CreatePDF from "../../_Components/Common/pdf/CreatePDF";
import { Scrollbar } from "react-scrollbars-custom";
import { useEffect, useState } from "react";
import axios from "axios";
import Activities from "./Activities";
import ScoredActivity from "./ScoredActivity";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";

const StudentProgress = () => {
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const stdId = searchParams.get("id");
  const studentName = searchParams.get("studentName");
  const courseId = searchParams.get("courseid");
  const coursenames = searchParams.get("coursename");
  const classSection =
    searchParams.get("stdclass") + searchParams.get("section");
    
  const scoredActivities = [];
  const nonScoredActivities = [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reportDataURL = `${process.env.REACT_APP_nodewsPrefix}/viewAllActivityCompletion`;
        const response = await axios.post(reportDataURL, {
          course_id: courseId,
          userid: stdId,
        });
        setActivityData(response.data.course_data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [courseId, classSection]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  
  if (coursenames === "English") {
    activityData?.forEach((section) => {
      if (Array.isArray(section?.scorm_data)) {
        section?.scorm_data.forEach((activity) => {
          if (activity.scromtype === 1) {
            scoredActivities.push({
              section_name: section.section_name,
              activity,
            });
          } else {
            nonScoredActivities.push({
              section_name: section.section_name,
              activity,
            });
          }
        });
      }
    });
  } else {
    activityData?.forEach((section) => {
      if (Array.isArray(section?.scorm_data)) {
        section?.scorm_data.forEach((activity) => {
          if (activity.scromtype === 1 && activity.scrom_name !== "Dictionary") {
            scoredActivities.push({
              section_name: section.section_name,
              activity,
            });
          } else {
            nonScoredActivities.push({
              section_name: section.section_name,
              activity,
            });
          }
        });
      }
    });
  }

  
  return (
    <MainCard
      cardTitle={`${coursenames} ${classSection}`}
      customClass = "progressCard"
      additionalData={
        <CreatePDF
          id={studentName}
          fileName="progress_report"
          orientation={"portrait"}
          btnTitle={"Download Report"}
          pdfHeading={"Report"}
        />
      }
    >
      <Typography className="progressHeader" variant="h6">
        Progress Report
      </Typography>
      <Scrollbar
        style={{ maxWidth: "100%", height: 600 }}
        className="customCardItem"
      >
        {loading ? (
          <CircularLoader />
        ) : (
          <Box id={studentName} className="progressContent">
            <Box display={"flex"} alignItems={"center"} gap={2} p={1}>
              <Typography>
                <b>Name:</b> {studentName}
              </Typography>
              <Typography>
                <b>Class:</b> {classSection}
              </Typography>
            </Box>
            <Box className="activitySec">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ScoredActivity
                    activity={scoredActivities}
                    heading="Scored Activity"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Activities
                    activity={nonScoredActivities}
                    heading="Non Scored Activity"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Scrollbar>
    </MainCard>
  );
};

export default StudentProgress;
