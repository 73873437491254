import axios from "axios";
import { useEffect } from "react";
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from "react";
import MainCard from "../Common/Cards/MainCard";
import { Box } from "@mui/material";
import "./Logs.scss";

const Logs = () => {
    const [logs, setLogs] = useState([])

    useEffect(() => {
        fetchLog()
    }, [])

    const fetchLog = async () => {
        let response = await axios.get(`${process.env.REACT_APP_nodewsPrefix}/getAllLogs?sort=true&limit=10`)
        setLogs(response.data.logs)
        // console.log(response.data, "log");
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 60 },
        { field: 'level', headerName: 'Level', width: 60 },
        { field: 'timecreated', headerName: 'Time Created', width: 130 },
        {
            field: 'router',
            headerName: 'Router',
            width: 200,
        },
        {
            field: 'error',
            headerName: 'Error',
            // renderCell: (params) => (
            //     <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            //         {params.value}
            //     </div>
            // ),
            width: 500,
        },
        {
            field: 'ip',
            headerName: 'IP',
            width: 100,
        }
    ];

    return (
        <MainCard cardTitle="Error Log" customClass="logsTable">
            <Box style={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={logs}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 4 },
                        },
                    }}
                /* pageSizeOptions={[5, 10]}
                checkboxSelection */
                />
            </Box>
        </MainCard>

    )

}
export default Logs;