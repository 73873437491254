import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { IoTrashOutline } from "react-icons/io5";
import "./SettingPage.scss";
import Loader from "../Utils/loader/Loader";
import BackButton from "../Common/BackBtn/BackBtn";
import { Scrollbar } from "react-scrollbars-custom";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const DevSettingsPage = () => {
  const [permissions, setPermissions] = useState();
  const [open, setOpen] = useState(false);
  const [newSection, setNewSection] = useState({
    role: "",
    department: "",
    section: "",
    read: false,
    write: false,
    canAccess: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getConfigData`
        );
        setPermissions(response.data.info);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchData();
  }, []);

  const handlePermissionChange = (role, section, permissionType) => {
    setPermissions((prevPermissions) => {
      const newPermissions = {
        ...prevPermissions,
        [role]: {
          ...prevPermissions[role],
          [section]: {
            ...prevPermissions[role][section],
            [permissionType]: !prevPermissions[role][section][permissionType],
          },
        },
      };
      return newPermissions;
    });
  };

  const handleFormData = async (e) => {
    e.preventDefault();
    const datas = JSON.stringify(permissions);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_nodewsPrefix}/updateConfigData?data=${datas}`
      );
      console.log("Updated successfully:", response.data);
      alert("Updated Successfully");
    } catch (error) {
      console.error("Error updating permissions:", error);
    }
  };

  const handleAddSection = () => {
    setPermissions((prevPermissions) => {
      const { role, department, section } = newSection;

      const newPermissions = {
        ...prevPermissions,
        [department]: {
          ...(prevPermissions[department] || {}),
          [section]: {
            ...(prevPermissions[role]?.[section] || {}),
          },
        },
      };

      return newPermissions;
    });

    setOpen(false);
    setNewSection({
      section: "",
      department: "",
      read: false,
      write: false,
      canAccess: false,
    });
  };
  const handleDeleteSection = (section, department) => {
    setPermissions((prevPermissions) => {
      const newPermissions = { ...prevPermissions };
      delete newPermissions[department][section];
      return newPermissions;
    });
  };
  const handleDeleteRole = (role) => {
    setPermissions((prevPermissions) => {
      const newPermissions = { ...prevPermissions };
      delete newPermissions[role];
      return newPermissions;
    });
  };

  return (
    <Container maxWidth="xl">
      <Card
        maxWidth="xl"
        sx={{
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
        className="customCard setCard"
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{
            borderColor: "borderLine.main",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box className="titleHead">
            <BackButton />
            <b>Settings Page</b>
          </Box>

          <Button variant="contained" onClick={() => setOpen(true)}>
            Add Fields
          </Button>
        </Typography>
        {!permissions ? (
          <Box>
            <Loader />
          </Box>
        ) : (
          <Card className="customCardContent settingCard" p={2}>
            <form onSubmit={handleFormData}>
              <CardContent className="cardContent">
                <Scrollbar
                  style={{ width: "100%", height: 600 }}
                  className="customCardItem"
                >
                  {Object.keys(permissions).map((role, index) => (
                    <Grid container spacing={2} key={index}>
                      <Grid item md={11.5}>
                        <Accordion className="customAccordion">
                          <AccordionSummary
                            expandIcon={<GridExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                          >
                            <Typography
                              variant="h6"
                              sx={{ textTransform: "capitalize" }}
                            >
                              <b>{role === "pedagogy" ? "Teacher" : role}</b>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              {Object.keys(permissions[role]).map((section) => (
                                <Grid key={section} item md={3} sm={6} xs={6}>
                                  <Box className="componentArea">
                                    <Typography
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {section}
                                    </Typography>
                                    <Box
                                      display={"flex"}
                                      alignItems={"center"}
                                      gap={2}
                                    >
                                      <label>
                                        Read:&nbsp;
                                        <input
                                          type="checkbox"
                                          checked={
                                            permissions[role][section].read
                                          }
                                          onChange={() =>
                                            handlePermissionChange(
                                              role,
                                              section,
                                              "read"
                                            )
                                          }
                                        />
                                      </label>
                                      <label>
                                        Write:&nbsp;
                                        <input
                                          type="checkbox"
                                          checked={
                                            permissions[role][section].write
                                          }
                                          onChange={() =>
                                            handlePermissionChange(
                                              role,
                                              section,
                                              "write"
                                            )
                                          }
                                        />
                                      </label>
                                      <label>
                                        CanAccess:&nbsp;
                                        <input
                                          type="checkbox"
                                          checked={
                                            permissions[role][section].canAccess
                                          }
                                          onChange={() =>
                                            handlePermissionChange(
                                              role,
                                              section,
                                              "canAccess"
                                            )
                                          }
                                        />
                                      </label>
                                    </Box>
                                    <Box
                                      className="icons"
                                      sx={{
                                        backgroundColor: "profileButton.main",
                                      }}
                                    >
                                      <IoTrashOutline
                                        onClick={() =>
                                          handleDeleteSection(section, role)
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      <Grid item md={0.5} 
                            className="roleBtns">
                        <Box
                          className="icons"
                          sx={{
                            backgroundColor: "profileButton.main",
                          }}
                        >
                          <IoTrashOutline
                            onClick={() => handleDeleteRole(role)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Scrollbar>
              </CardContent>

              <CardActions className="settingSave">
                <Box textAlign={"center"} width={"100%"}>
                  <Button type="submit" variant="contained" className="btn">
                    Save
                  </Button>
                </Box>
              </CardActions>
            </form>
            <Box>
              <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Add Department and Section</DialogTitle>
                <DialogContent>
                  <TextField
                    label="Department"
                    value={newSection.department}
                    onChange={(e) =>
                      setNewSection({
                        ...newSection,
                        department: e.target.value,
                      })
                    }
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Section"
                    value={newSection.section}
                    onChange={(e) =>
                      setNewSection({ ...newSection, section: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)}>Cancel</Button>
                  <Button onClick={handleAddSection}>Add Section</Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Card>
        )}
      </Card>
    </Container>
  );
};

export default DevSettingsPage;
