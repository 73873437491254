import { useNavigate } from "react-router-dom";
import "./BackBtn.scss";

const BackButton = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <img
        onClick={goBack}
        className="goBack"
        src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/backBtn.png`}
        alt="backBtn"
        width={45}
      />
    </>
  );
};

export default BackButton;
