import React from "react";
import SimpleSlider from "./Slider/Slider";
import { Box, Container, Grid, Typography } from "@mui/material";
import "./Home.scss";
import Features from "./Features";
import FeatureCard from "../../_Components/Common/Cards/FeatureCard";

const Home = () => {
  return (
    <Box className="homeWrapper">
      <SimpleSlider />

      <Box sx={{ backgroundColor: "#fdf5e6" }}>
        <Features />
      </Box>

      <Box className="OurVision spacing" pb={0}>
        <Container maxWidth="xl">
          <Typography variant="h2" align="center" className="title">
            Our Vision
          </Typography>
          <Grid container spacing={8}>
            <Grid item md={7} >
              <Typography pt={8}>
                Our vision is to transform the learning experience by creating
                an innovative platform that seamlessly integrates e-books,
                attendance tracking, comprehensive reporting and efficient
                assignment management. We aim to empower educators and learners,
                fostering engagement, efficiency and excellence in education.
              </Typography>
            </Grid>
            <Grid item md={5} className="visionSec" >
              <Box className="visionImgSec">
                <img
                  src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/slider/vision.png`}
                  alt=""
                />
              </Box>
              {/* <Box className="visionImgStdSec">
                <img
                  src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/slider/visionImg1.jpg`}
                  alt=""
                />
              </Box> */}
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="countSec spacing">
        <Container maxWidth="xl">
          <Grid container>
            <Grid item md={3}>
              <Box className="numberSec">10K+</Box>
              <Box className="textSec">Number of Schools All Over India</Box>
            </Grid>
            <Grid item md={3}>
              <Box className="numberSec">30L+</Box>
              <Box className="textSec">Number of Students</Box>
            </Grid>
            <Grid item md={3}>
              <Box className="numberSec">20+</Box>
              <Box className="textSec">Year in Digital Publication </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="numberSec">30+</Box>
              <Box className="textSec">Types of Digital Assets</Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="aimSec spacing">
        <Container maxWidth="xl">
          <Typography variant="h2" align="center" className="title">
            Our Aim
          </Typography>
          <Grid container spacing={4}>
            <FeatureCard
              cardHeading="Empowering Educators"
              cardIcons="aimIcon1.png"
              altTxt="Empowering"
              className="aimSec" lgSize={3} mdSize={3} sxSize={4} smSize={6}
            >
              <Typography>
                Provide tools and resources that empower educators to create
                engaging, interactive and effective learning experiences.
              </Typography>
            </FeatureCard>

            <FeatureCard
              cardHeading="Accessible Learning"
              cardIcons="aimIcon2.png"
              altTxt="Accessible-Learning"
              className="aimSec" lgSize={3} mdSize={3} sxSize={4} smSize={6}
            >
              <Typography>
                Ensure accessibility for all learners, anywhere, anytime.
              </Typography>
            </FeatureCard>
            <FeatureCard
              cardHeading="Data-driven Insights"
              cardIcons="aimIcon3.png"
              altTxt="Data-driven"
              className="aimSec" lgSize={3} mdSize={3} sxSize={4} smSize={6}
            >
              <Typography>
                Utilize data analytics to provide actionable insights for
                educators to optimize teaching methods and improve student
                outcomes.
              </Typography>
            </FeatureCard>
            <FeatureCard
              cardHeading="Collaborative Learning"
              cardIcons="aimIcon4.png"
              altTxt="Collaborative"
              className="aimSec" lgSize={3} mdSize={3} sxSize={4} smSize={6}
            >
              <Typography>
                Foster a collaborative learning environment that encourages
                interaction, discussion and knowledge sharing among learners and
                educators.
              </Typography>
            </FeatureCard>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
