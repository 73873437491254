import axios from "axios";
import "./assessmentAttempt.css";
import { useLocation } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import { Button, Card, Grid, Typography } from "@mui/material";

import { Container } from "@mui/system";
import QuizDescription from "../Quiz/QuizDescription";
import Quiznavigation from "../Quiz/Quiznavigation";
import QuizQuestions from "../Quiz/QuizQuestions";
import { languages } from "../../App";
import Loader from "../../_Components/Utils/loader/Loader";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const answerFlaged = [];

function AssessmentAttempt() {
    const location = useLocation();
    console.log(location.state)
    const token = localStorage.getItem("token");
    let curLanguage = useContext(languages);

    const [quesData, setquesData] = useState();
    const quizID = location.state.quizID;
    const [isLoading, setIsLoading] = useState(false);
    const [pageNo, setpageNo] = useState(0);
    const [studentAnswers, setUserAnswers] = useState([]);
    const [flagClicked, setFlagClicked] = useState(0)
    let questDisplay = {}
    let attemptId;

    if (location.state.quizSummarycheck === "yes") {
        attemptId = location.state.attemptid;
        console.log("from review", location.state);
    } else {
        attemptId = location.state.id;
        console.log("direct", location.state);
    }

    

    const getQuestionData = async (url) => {
        console.log(url);
        setIsLoading(true)
        await axios.get(url).then(
            (response) => {
                console.log(response.data)
                setquesData(response.data);
            }
        )
        setIsLoading(false)
    }



    let quesHTMLDataURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_attempt_data_info&moodlewsrestformat=json&attemptid=${attemptId}&page=${pageNo}`;

    

    useEffect(() => {

        if (location.state?.quizSummarycheck) {
            console.log("from Summary")
            setpageNo(location.state.page);
        }
    }, [location.state.page]);

    useEffect(() => {
        console.log("from NAVIGATION")
        getQuestionData(quesHTMLDataURL);
    }, [quesHTMLDataURL]);


    const nextQuestion = () => {
        if (pageNo + 1 < questDisplay.noOfQuestion) {
            setpageNo(prevState => prevState + 1)
        }
    }

    const previousQuestion = () => {
        if (pageNo > 0) {
            setpageNo(prevState => prevState - 1)
        }
    }

    const flagQuestion = () => {
        getQuestionData(quesHTMLDataURL);
        //setFlagClicked(prev => prev + 1);
    }

    if (quesData && quesData.hasOwnProperty("message")) {


    } else if (quesData) {
        questDisplay.quizID = quizID;
        questDisplay.assesmentId = attemptId;
        questDisplay.noOfQuestion = quesData.attempt.layout.split(",").length / 2;
        questDisplay.questionType = quesData.questions[0].type;
        questDisplay.questionText = JSON.parse(quesData.questions[0].html).questiontext;
        questDisplay.answer = JSON.parse(quesData.questions[0].html).answers_data;
        questDisplay.attempt = quesData.questions[0].status;
        questDisplay.qid = JSON.parse(quesData.questions[0].html).question_id;
        questDisplay.qNo = pageNo;
        questDisplay.previousQuestion = previousQuestion;
        questDisplay.nextQuestion = nextQuestion;
        questDisplay.pageNo = pageNo;
        questDisplay.setPageNo = setpageNo;
        questDisplay.answerFlaged = quesData.questions[0].flagged //answerFlaged;
        questDisplay.answerAttempt = JSON.parse(quesData.questions[0].html).answer_response;
        questDisplay.formElements = JSON.parse(quesData.questions[0].html).formelements;
        questDisplay.checksum = JSON.parse(quesData.questions[0].html).checksum.split("&amp")[0];
        questDisplay.qubaid = quesData.attempt.uniqueid;
        questDisplay.qaid = JSON.parse(quesData.questions[0].html).qaid;
        questDisplay.flagQuestion = flagQuestion;
    }

    return (
        <main className="dashboardMain">
            <Container maxWidth="xl" sx={{ display: "flex" }}>

                <Grid container spacing={2} className="quizQuestionContainer">

                <QuizQuestions qdata={questDisplay}/>

                </Grid>
            </Container>
        </main>
    );
}

export default AssessmentAttempt;
