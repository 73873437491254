import { Box } from "@mui/material";
import { createContext, useContext, useState, useEffect } from "react";

const PermissionsContext = createContext();

const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(null);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  try {
    var department = user?.[0].department.toLowerCase();
    var deptUrl = user?.[0].department.toLowerCase() === "developer";
  } catch (error) {
    console.log("errorr");
    localStorage.clear();
  }

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        let response;
       
        if (deptUrl) {
          response = await fetch(
            `${
              process.env.REACT_APP_nodewsPrefix
            }/getDeveloperConfig?department=${department.toLowerCase()}`
          );
        } else if (!department || department === "") {
          response = await fetch(
            `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept?department=guest`
          );
        } else {
          response = await fetch(
            `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept?department=${department}`
          );
        }

        const data = await response.json();
        setPermissions(data.info);
      } catch (error) {
        console.error("Error fetching permissions:", error);
        setPermissions(null);
        localStorage.clear();
      }
    };

    fetchPermissions();
  }, []);

  return (
    <PermissionsContext.Provider value={{ permissions }}>
      {permissions === null ? (
        <Box className="loaderSec">
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/loader.gif`}
            alt="loader"
          />
        </Box>
      ) : (
        children
      )}
    </PermissionsContext.Provider>
  );
};

const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionsProvider");
  }
  return context;
};

export { PermissionsProvider, usePermissions };
