import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link, useLocation } from "react-router-dom";
import styles from "./footer.module.scss";
import { Box, Button, Card, Typography } from "@mui/material";
import { languages } from "../../App";
import IconWithText from "../../_Components/Utils/IconWithText/IconWithText";
import FooterSocialMedia from "./FooterSocialMedia";

const Footer = () => {
  const location = useLocation();
  let curLanguage = useContext(languages);
  const isHomeScreen = location.pathname === "/";

  const scrollTopBtn = () => {
    const scrollableContent = document.querySelector(".contentWrapper");
    if (scrollableContent) {
      scrollableContent.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const date = new Date();
  const year = date.getFullYear();
  let dyTxt = `<a href=''>rsagar@ratnasagar.com</a>`;
  return (
    <>
      {isHomeScreen ? (
        <footer className={styles["footer-main"]}>
          <Box className={styles["footer-wrapper"]}>
            <Container maxWidth="xl" className={styles.footerContent}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} className={styles["footer-box"]}>
                  <Card
                    className={styles["footer-wrapper-col"]}
                    sx={{
                      color: "footerBg.contrastText",
                      backgroundColor: "footerBg.bgColor",
                    }}
                  >
                    <Box className={styles["footer-logo"]}>
                      <Link to="/">
                        <img
                          src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/logo-w.png`}
                          alt="Ratna Sagar logo"
                        />
                      </Link>
                    </Box>
                    <Box>
                      Ratna Sagar is one of the largest and most renowned
                      publishing houses in India today. Ratna Sagar is named
                      after a library of the Nalanda University, an ancient seat
                      of learning which flourished over 2000 years ago.<br></br>
                      <Link to="https://ratnasagar.com/aboutus.aspx">
                        {curLanguage.readMore} »
                      </Link>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4} className={styles["footer-box"]}>
                  <Card
                    className={styles["footer-wrapper-col"]}
                    sx={{
                      color: "footerBg.contrastText",
                      backgroundColor: "footerBg.bgColor",
                    }}
                  >
                    <Box className={styles["footer-wrapper-links"]}>
                      <Typography variant="h3">{curLanguage.info}</Typography>
                      <ul>
                        <li>
                          {" "}
                          <Link
                            to="http://ratnasagar.com/home.aspx"
                            target="_blank"
                          >
                            {curLanguage.visitUs}
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            to="https://ratnasagar.com/aboutus.aspx"
                            target="_blank"
                          >
                            {curLanguage.aboutUs}
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            to="https://ratnasagar.com/quality-policy.aspx"
                            target="_blank"
                          >
                            {curLanguage.qualityPolicy}
                          </Link>
                        </li>
                      </ul>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4} className={styles["footer-box"]}>
                  <Card
                    className={styles["footer-wrapper-col"]}
                    sx={{
                      color: "footerBg.contrastText",
                      backgroundColor: "footerBg.bgColor",
                    }}
                  >
                    <Box className={styles["contact-info"]}>
                      <Typography variant="h3">
                        {curLanguage.contactUs}
                      </Typography>
                      <Box>
                        <Box mb={2}>
                          Virat Bhawan, <br></br>Mukherjee Nagar Commercial
                          Complex,
                          <br></br>New Delhi-110009<br></br>
                        </Box>
                        <Box className={styles["contact-info--icon"]}>
                          <IconWithText
                            tag="phone"
                            text="Phone: +91 11 4703 8000"
                            bg={false}
                          />
                        </Box>
                        <Box className={styles["contact-info--icon"]}>
                          <IconWithText
                            tag="mail"
                            text="Email: rsagar@ratnasagar.com"
                            Link="mailto:rsagar@ratnasagar.com"
                            bg={false}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <FooterSocialMedia />
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Grid
            className={styles["footer_copyright"]}
            sx={{ bgcolor: "footer.bgColor" }}
          >
            <Grid item xs={12} className={styles["copyright"]}>
              <Box className={styles["text"]}>
                Copyright &copy; {year} - Developed by{" "}
                <Link to="/">Ratna Sagar</Link>.
              </Box>
              <Button
                sx={{ backgroundColor: "resourceIcon.main" }}
                data-tooltip="Scroll to top"
                className={styles["top"]}
                onClick={scrollTopBtn}
              >
                <KeyboardArrowUpIcon />
              </Button>
            </Grid>
          </Grid>
        </footer>
      ) : (
        <Box
          className={styles["inside_footer"]}
          sx={{ bgcolor: "footer.bgColor" }}
        >
          <Container maxWidth={"xl"}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <Box>
                Copyright &copy; {year} - Developed by{" "}
                <Link to="/">Ratna Sagar</Link>.
              </Box>

              <FooterSocialMedia />
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};
export default Footer;
