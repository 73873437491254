import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
} from "@mui/material";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import { useState } from "react";
import axios from "axios";

const ChangePassword = () => {
  const [open, setOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const userid = userinfo[0]?.id;
  const token = localStorage.getItem("token");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "currentPassword") setCurrentPassword(value);
    else if (name === "newPassword") setNewPassword(value);
    else if (name === "confirmPassword") setConfirmPassword(value);
  };

  const handleSubmit = () => {
    // Perform validation
    if (!currentPassword || !newPassword || !confirmPassword) {
      setErrorMessage("Please fill all the fields.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    // If all validations pass, you can make an API call to update the password

    const baseURL = `${process.env.REACT_APP_wsPrefix}/customwebservice/user/password.php?wstoken=${token}&wsfunction=password_change`;
    axios
      .put(baseURL, {
        oldpassword: currentPassword,
        newpassword: newPassword,
        confirmpassword: confirmPassword,
        userid: userid,
      })
      .then((response) => {
        console.log(response, "password");
      });
    // Here, we'll write the API to send the value
    setSuccessMessage("Password updated successfully.");
    console.log(currentPassword, newPassword, "password");

    // Reset the form fields
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} className="btn" variant="contained">
        Change Password
      </Button>

      <CustomDialog isOpen={open} width={"xs"} onClose={() => setOpen(false)}>
        <DialogTitle
          className="title"
          sx={{
            backgroundColor: "popup.titleHeaderColor",
            color: "popup.popupheadertextcolor",
          }}
        >
          Change Password
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            sx={{ mb: 2}} 
            label="Current Password"
            type="password"
            name="currentPassword"
            value={currentPassword}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            sx={{ mb: 2, color: errorMessage ? "red" : "inherit" }} 
            label="New Password"
            type="text"
            name="newPassword"
            value={newPassword}
            onChange={handleChange}
            error={Boolean(errorMessage)} 
            helperText={errorMessage} 
          />

          <TextField
            fullWidth
            sx={{ mb: 2, color: errorMessage ? "red" : "inherit", borderColor: errorMessage ? "red" : "inherit"  }} 
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleChange}
            error={Boolean(errorMessage)} 
            helperText={errorMessage} 
          />

          <Box textAlign={"center"}>
            <Button
              variant="contained"
              className="btn"
              color="primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>

          <Snackbar
            open={!!errorMessage}
            onClose={() => setErrorMessage("")}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={60000}
          >
            <SnackbarContent
              sx={{ backgroundColor: "greenColor.color" }}
              message={errorMessage}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setErrorMessage("")}
                >
                  OK
                </Button>
              }
            />
          </Snackbar>

          <Snackbar
            open={!!successMessage}
            onClose={() => setSuccessMessage("")}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={6000}
          >
            <SnackbarContent
              sx={{ backgroundColor: "greenColor.color" }}
              message={successMessage}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setSuccessMessage("")}
                >
                  OK
                </Button>
              }
            />
          </Snackbar>
        </DialogContent>
      </CustomDialog>
    </>
  );
};

export default ChangePassword;
