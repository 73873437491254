  import { useState } from "react";
  import MainCard from "../../../_Components/Common/Cards/MainCard";
  import PrincipalFilter from "../../../_Components/Common/Filter/PrincipalFilter";
  import { Box, Card, Container } from "@mui/material";
  import StudentProgressDetail from "../../ProgressReport/StudentProgressDetail";
import UserReportData from "./UserReportData";

  const UserReports = () => {
    const [filterClass, setFilterClass] = useState("");
    const [filterSection, setFilterSection] = useState("");
    const [filterSubject, setFilterSubject] = useState("");
    const [filterDepartment, setFilterDepartment] = useState("");

    // Get student info for filtering purposes
    const handleFilter = (stdclass, stdsection, stdsubject, department) => {
      setFilterClass(stdclass);
      setFilterSection(stdsection);
      setFilterSubject(stdsubject);
      setFilterDepartment(department);
      console.log(stdclass, stdsection, stdsubject, department, "filter value");
    };
    return (
      <>
        <MainCard cardTitle="User Report">
          <PrincipalFilter
            forDepartment
            forClass
            forSection
            forSubject
            forStudent
            onselectionchangeData={handleFilter}
          />
        </MainCard>
        {filterDepartment && (
          <>
            {filterDepartment === "student" && (
              <Box sx={{ mt: 2 }}>
                <UserReportData
                  filterCourseID={filterSubject}
                  filterCoursenames="English"
                  filterClass={filterClass}
                  filterSection={filterSection}
                  filterStudent=""
                />
              </Box>
            )}
            {filterDepartment === "Pedagogy" && (
              <Container maxWidth="xl">
                <Card
                  sx={{
                    backgroundColor: "cardColor.main",
                    color: "cardColor.contrast",
                    position: "relative",
                    marginTop: "16px",
                    padding: "10px 0 ",
                  }}
                  elevation={5}
                  className="customCard"
                >
                  <p>pedagogy</p>
                </Card>
              </Container>
            )}
          </>
        )}
      </>
    );
  };

  export default UserReports;
