import { Box, Typography } from "@mui/material";
import React from "react";
import "./CardSecondary.scss"

const CardSecondary = ({ curLanguage, children }) => {
    return (

      <>
      <Box className="recentActivity recentSec" sx={{ backgroundColor: "darkblue.color" }}>
        <Typography
          className="recentItemHeader"
          variant="h3"
          sx={{ color: "recent.titleColor", backgroundColor: "secondaryColor.main" }}
        >
          {curLanguage}
        </Typography>
        <Box className="recentList">
            {children}
            </Box>
        </Box>
      </>

    )
}
export default CardSecondary