import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Calendar } from "react-calendar";
import AttendReport from "./AttendReport";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const AttendanceCalendar = ({ attendanceData, stdId, stdName }) => {
  const [date, setDate] = useState(new Date());
  const [attendanceReport, setAttendanceReport] = useState(null);

  useEffect(() => {
    const calculateAttendanceReport = () => {
      const currentMonthKey = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(date);

      if (
        attendanceData &&
        attendanceData[stdId] &&
        attendanceData[stdId][currentMonthKey]
      ) {
        const currentMonthData = attendanceData[stdId][currentMonthKey];

        const presentCount = currentMonthData.filter(
          (status) => status === "P"
        ).length;
        const absentCount = currentMonthData.filter(
          (status) => status === "A"
        ).length;
        const halfDayCount = currentMonthData.filter(
          (status) => status === "H"
        ).length;

        const data = {
          labels: ["Present", "Absent", "Half Day"],
          datasets: [
            {
              data: [presentCount, absentCount, halfDayCount],
            },
          ],
        };

        setAttendanceReport(data);
      } else {
        setAttendanceReport(null);
      }
    };

    calculateAttendanceReport();
  }, [attendanceData, stdId, date]);

  const formatAttendanceData = (date) => {
    const month = date.getMonth();
    const day = date.getDate();

    const currentMonthKey = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(date);
    const currentMonthData = attendanceData[stdId]?.[currentMonthKey];

    if (currentMonthData && currentMonthData[day - 1] !== undefined) {
      return currentMonthData[day - 1];
    }

    return null;
  };

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const dayAttendance = formatAttendanceData(date);

      return dayAttendance ? (
        <CustomTile
          key={date.toString()}
          date={date}
          attendanceStatus={dayAttendance}
        />
      ) : null;
    }
    return null;
  };

  const CustomTile = ({ date, attendanceStatus }) => {
    const buttonClass =
      attendanceStatus === "P"
        ? "P"
        : attendanceStatus === "A"
        ? "A"
        : attendanceStatus === "H"
        ? "H"
        : "";

    return (
      <div className={`calendar-day-button custom-tile ${buttonClass}`}>
        <span className="day-number">{date.getDate()}</span>
        <span className="attendance-status">{attendanceStatus}</span>
      </div>
    );
  };

  const handleCalendarChange = (newDate) => {
    setDate(newDate);
    updateAttendanceData(newDate);
  };
  const updateAttendanceData = (newDate) => {
    const currentMonthKey = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(newDate);

    const currentMonthData = attendanceData[stdId][currentMonthKey];

    if (currentMonthData) {
      const presentCount = currentMonthData.filter(
        (status) => status === "P"
      ).length;
      const absentCount = currentMonthData.filter(
        (status) => status === "A"
      ).length;
      const halfDayCount = currentMonthData.filter(
        (status) => status === "H"
      ).length;

      const data = {
        labels: ["Present", "Absent", "Half Day"],
        datasets: [
          {
            data: [presentCount, absentCount, halfDayCount],
          },
        ],
      };

      setAttendanceReport(data);
    }
  };

  const handleNextMonth = () => {
    const newMonth = date.getMonth() + 1;
    const newYear = date.getFullYear();
    let newDate = new Date(newYear, newMonth, date.getDate());
    if (newMonth > 11) {
      newDate = new Date(newYear, 11, date.getDate());
    }
    setDate(newDate);
    updateAttendanceData(newDate);
  };

  const handlePrevMonth = () => {
    const newMonth = date.getMonth() - 1;
    const newYear = date.getFullYear();
    let newDate = new Date(newYear, newMonth, date.getDate());
    if (newMonth < 0) {
      newDate = new Date(newYear, 0, date.getDate());
    }
    setDate(newDate);
    updateAttendanceData(newDate);
  };
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        mb={1}
        className="monthDetail"
      >
        <Button onClick={handlePrevMonth} title="Previous Month">
          <IoChevronBack size={20} />
        </Button>
        <Typography variant="h5">
          <b>{stdName}'s Record</b>
        </Typography>

        <Button onClick={handleNextMonth} title="Next Month">
          <IoChevronForward size={20} />
        </Button>
      </Box>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item md={5}>
          <Calendar
            showNeighboringMonth={false}
            onChange={(newDate) => handleCalendarChange(newDate)}
            value={date}
            tileContent={tileContent}
          />
          <Box className="label-tile">
            <Typography className="P label-data">Present</Typography>
            <Typography className="A label-data">Absent</Typography>
            <Typography className="H label-data">Half Day</Typography>
          </Box>
        </Grid>
        <Grid item md={7}>
          {attendanceReport && (
            <AttendReport attendanceReport={attendanceReport} echartsKey={0} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AttendanceCalendar;
