import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./_Components/Routing/Router";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  ThemeProvider,
  styled,
} from "@mui/material";
import DefaultTheme from "./_Components/Themes/theme";
import theme2 from "./_Components/Themes/theme2";
import { createContext, useEffect, useState } from "react";
import LanguagePack from "./_Components/Utils/LanguagePack";
import ScrollToTop from "./_Components/Utils/ScrollTop";
import { Container } from "@mui/system";
import { PermissionsProvider } from "./_Components/Permission/PermissionsContext";
export const languages = createContext();

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return !!token;
};


function App() {
  const [theme, setTheme] = useState(DefaultTheme);
  const [checked, setChecked] = useState({ val: false, text: "" });
  const [language, setLanguage] = useState("English");

  const handleChange = (event) => {
    setTheme(theme === theme2 ? DefaultTheme : theme2);
    setChecked({
      val: event.target.checked,
      text: event.target.checked ? "" : "",
    });
  };
  const changeLanguage = (e) => {
    setLanguage(e.target.value);
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 27,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(30px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#204abd",
      width: 25,
      height: 25,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <languages.Provider value={LanguagePack[language]}>
        <div className="pure-container" data-effect="pure-effect-slide">
          <Container maxWidth="xl" sx={{ position: "relative", display: "none" }}>
            <div className="headAction">
              <FormControlLabel
                sx={{
                  color: "switchButton.contrastText",
                  marginRight: "0",
                }}
                className="themeChangeSwitch"
                control={
                  <MaterialUISwitch
                    sx={{ m: 0 }}
                    className="switchCircle"
                    checked={checked.val}
                    onChange={handleChange}
                  />
                }
                label={checked.text}
                labelPlacement="start"
              />

              <FormControl sx={{ mt: 1, mb: 1, minWidth: 80 }}>
                <Select value={language} onChange={changeLanguage} size="small">
                  <MenuItem value={"English"} className="flli">
                    En{" "}
                    <img
                      width="30px"
                      className="flimg"
                      src={`${process.env.REACT_APP_wsPrefix}/lms-images/flags/EN.png`}
                      alt="uslogo"
                    />
                  </MenuItem>
                  <MenuItem value={"Hindi"} className="flli">
                    Hi{" "}
                    <img
                      width="30px"
                      className="flimg"
                      src={`${process.env.REACT_APP_wsPrefix}/lms-images/flags/IN.png`}
                      alt="Indialogo"
                    />
                  </MenuItem>
                  <MenuItem value={"German"} className="flli">
                    De{" "}
                    <img
                      width="30px"
                      className="flimg"
                      src={`${process.env.REACT_APP_wsPrefix}/lms-images/flags/DE.png`}
                      alt="germanlogo"
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Container>
          {/* For Routing code start here */}
          <PermissionsProvider isAuthenticated={isAuthenticated}> 
            <div className={`App ${theme === theme2 ? "dark" : "light"}`}>
              <BrowserRouter>
                <ScrollToTop />
                <Router />
              </BrowserRouter>
            </div>
            {/* For Routing code end here */}
          </PermissionsProvider>
        </div>
      </languages.Provider>
    </ThemeProvider>
  );
}

export default App;
