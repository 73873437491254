import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import GetCalendarEvent from "./getCalendarEvent/getCalendarEvent";
import "./calendar.scss";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CreateEventContainer from "../../containers/createEventContainer";
import { languages } from "../../App";
import MainBackBtn from "../../_Components/Common/BackBtn/MainBackBtn";

function Calendar() {
  let curLanguage = useContext(languages);
  const [initialEvent, setInitialEvent] = useState();
  const [calendarData, setCalendarData] = useState({});
  const [eventid, seteventid] = useState();
  const [popupVisible, setpopupVisible] = useState(false);
  const [createnew, setCreateNewPop] = useState(false);
  const [confirm, setConfirm] = useState({ isShow: false, eventCount: null });
  const [datecal, setDatecal] = useState();
  const token = localStorage.getItem("token");
  let count = 0;

  const d = new Date();
  let tmpM = d.getMonth();
  let tmpY = d.getFullYear();
  let tmpD = d.getDate();
  const [monthYearCount, setMonthYearCount] = useState({
    year: tmpY,
    month: tmpM,
  });
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    const baseURL = `${
      process.env.REACT_APP_wsPrefix
    }/webservice/rest/server.php?wstoken=${token}&wsfunction=core_calendar_get_calendar_monthly_view&moodlewsrestformat=json&year=${
      monthYearCount.year
    }&month=${monthYearCount.month + 1}`;
    axios
      .get(baseURL)

      .then((response) => {
        setCalendarData(response.data);
        console.log(response.data, "responsedata");
      });
  }, [monthYearCount, popupVisible]);

  const handleEventUpdated = (eventId) => {
    setInitialEvent(eventId);
  };

  const handleEventClick = (e) => {
    e.stopPropagation();
    seteventid(e.target.getAttribute("data-id"));
    setpopupVisible(true);
  };

  const handleCalenderDateClick = (e) => {
    setDatecal(e.currentTarget.getAttribute("data-date"));
    setCreateNewPop(true);
  };

  const allEventsLi = (eventArr) => {
    let eList = [];
    let oList = [];
    let storedEvents = [];
    console.log(initialEvent, "eventId in parent");

    eventArr.map((val) => {
      if (val.component || val.eventtype === "course" || val.canedit) {
        count++;
        eList.push({ ...val });
        storedEvents.push({ ...val });

        if (val.eventtype === "open") {
          oList.push(val.instance);
        } else if (val.eventtype === "close") {
          let iid = oList.indexOf(val.instance);
          if (iid > -1) {
            storedEvents[iid].eventtype = "open-close";
            eList[iid].eventtype = "open-close";
            eList.pop();
          }
        }
      }
    });

    const BootstrapTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        // color: theme.palette.tooltip.main,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.tooltip.backgroundColor,
        color: theme.palette.tooltip.main,
        border: "1px solid white",
      },
    }));

    let eventsli = eList.map((val, indx) => {
      if (val.component || val.eventtype === "course" || val.canedit) {
        // activity based
        if (val.component) {
          if (val.eventtype === "open" || val.eventtype === "close") {
            return (
              <li key={val.id}>
                <BootstrapTooltip title={val.name}>
                  <Button
                    key={val.id}
                    color="categoryEventBtn"
                    variant="contained"
                    className={`eventsButton actB${val.eventtype}`}
                    id="activityBased"
                    data-id={val.id}
                    onClick={handleEventClick}
                  >
                    {val.name}
                  </Button>
                </BootstrapTooltip>
              </li>
            );
          } else if (val.eventtype === "open-close") {
            return (
              <li key={val.id}>
                <BootstrapTooltip title={val.name}>
                  <Button
                    key={val.id}
                    color="categoryEventBtn"
                    variant="contained"
                    className={`eventsButton actB`}
                    id="activityBased"
                    data-id={val.id}
                    onClick={handleEventClick}
                  >
                    {val.name}
                  </Button>
                </BootstrapTooltip>
              </li>
            );
          }
        } else if (val.eventtype === "course") {
          // techer event
          return (
            <li key={val.id}>
              <BootstrapTooltip title={val.name}>
                <Button
                  key={val.id}
                  color="teacherEventBtn"
                  variant="contained"
                  className="eventsButton"
                  id="teacherEvent"
                  data-id={val.id}
                  onClick={handleEventClick}
                >
                  {val.name}
                </Button>
              </BootstrapTooltip>
            </li>
          );
        } else if (val.canedit) {
          // student event
          return (
            <li key={val.id}>
              <BootstrapTooltip title={val.name}>
                <Button
                  key={val.id}
                  color="userEventBtn"
                  variant="contained"
                  className="eventsButton"
                  id="studentEvent"
                  data-id={val.id}
                  onClick={handleEventClick}
                >
                  {val.name}
                </Button>
              </BootstrapTooltip>
            </li>
          );
        }
      }
    });

    if (eventsli.length <= 4) {
      return eventsli;
    } else {
      let eventLeft = eventsli.length - 4;
      let esli1 = eventsli.slice(0, 4);
      esli1.push(
        <li>
          <Button
            color="extraButton"
            variant="text"
            className="eventsButton"
          >{`${eventLeft} more`}</Button>
        </li>
      );
      return esli1;
    }
  };

  const onPopupClose = () => {
    setpopupVisible(false);
    setConfirm({ isShow: false, eventCount: null });
  };

  const handleCalendarNextPrev = (e) => {
    let tempY = monthYearCount.year;
    let tempM = monthYearCount.month;

    if (e.target.getAttribute("id") === "next") {
      if (tempM >= 12) {
        tempY = monthYearCount.year + 1;
        tempM = 0;
      }
      tempM = tempM + 1;
    } else {
      if (tempM <= 0) {
        tempY = monthYearCount.year - 1;
        tempM = 12;
      }
      tempM = tempM - 1;
    }

    setMonthYearCount({ year: tempY, month: tempM });
  };

  return (
    <>
      <Card
        className="calendar-container"
        sx={{
          color: "calendar.whiteColor",
          backgroundColor: "calendar.containerbgColor",
          borderColor: "calendar.borderColor",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <MainBackBtn />
          <Typography
            variant="h3"
            sx={{
              color: "calendar.whiteColor",
              backgroundColor: "calendar.bgcolor",
            }}
            className="calendarTitle"
          >
            <CalendarMonthIcon /> {curLanguage.mycalendar}
          </Typography>
        </Box>

        <Grid
          className="calendarHeader"
          sx={{
            color: "calendar.bgcolor",
            borderColor: "calendar.bgcolor",
          }}
        >
          <Button
            startIcon={
              <ArrowBackIosIcon
                className="arrowIcon"
                sx={{ color: "calendar.whiteColor" }}
              />
            }
            variant="contained"
            sx={{ boxShadow: "none", backgroundColor: "calendar.bgcolor" }}
            color="calendarButton"
            id="prev"
            className="arrow"
            onClick={handleCalendarNextPrev}
          >
            {calendarData.previousperiodname}{" "}
          </Button>
          <Typography
            variant="h4"
            sx={{ color: "calendar.bgcolor", fontWeight: "600" }}
          >
            {calendarData.periodname}
          </Typography>
          <Button
            endIcon={
              <ArrowForwardIosIcon
                className="arrowIcon"
                sx={{ color: "calendar.whiteColor" }}
              />
            }
            variant="contained"
            sx={{ boxShadow: "none", backgroundColor: "calendar.bgcolor" }}
            color="calendarButton"
            id="next"
            className="arrow"
            onClick={handleCalendarNextPrev}
          >
            {calendarData.nextperiodname}
          </Button>
        </Grid>

        {calendarData.weeks
          ? calendarData.weeks.map((val, index) => {
              return (
                <Grid container key={index} className="week-cont">
                  {calendarData.weeks[index].days.map((val, indx) => {
                    return (
                      <Grid
                        key={index}
                        className={
                          tmpD === val.mday
                            ? "activeDays-cont days-cont"
                            : "days-cont"
                        }
                        variant={
                          tmpD === val.mday
                            ? "activeCalendar"
                            : "inactiveCalendar"
                        }
                      >
                        <Button
                          variant="outlined"
                          className={`${days[val.wday]} calendarCell`}
                          data-date={val.mday}
                          onClick={handleCalenderDateClick}
                        >
                          <Box component="span" className="eventListWrapper">
                            <Grid>
                              <Grid
                                className="dateNum"
                                sx={{
                                  color: "secondaryColor.main",
                                  ":hover": {
                                    color: "#fff",
                                  },
                                }}
                              >
                                {val.mday}
                              </Grid>
                              <Grid
                                className="dateWeek"
                                sx={{ color: "secondaryColor.main" }}
                              >
                                {days[val.wday]}
                              </Grid>
                            </Grid>

                            {val.events.filter((filtEvent) => {
                              return (
                                filtEvent.component ||
                                filtEvent.eventtype === "course" ||
                                filtEvent.canedit
                              );
                            }).length ? (
                              <Box component="span" className="calHaveEvents">
                                {
                                  val.events.filter((filtEvent) => {
                                    return (
                                      filtEvent.component ||
                                      filtEvent.eventtype === "course" ||
                                      filtEvent.canedit
                                    );
                                  }).length
                                }
                              </Box>
                            ) : (
                              ""
                            )}

                            {val.events.filter((filtEvent) => {
                              return (
                                filtEvent.component ||
                                filtEvent.eventtype === "course" ||
                                filtEvent.canedit
                              );
                            }).length > 0 && (
                              <Box component="span" className="calSepEvents">
                                <ul className="eventlist">
                                  {allEventsLi(val.events)}
                                </ul>
                              </Box>
                            )}
                          </Box>
                          <Box className="attendanceMarker">
                            <span
                              className="attenMarkerText"
                              aria-label="Present"
                            ></span>
                          </Box>
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })
          : ""}
      </Card>

      <Dialog
        open={popupVisible}
        close={onPopupClose}
        className="eventsDialogue"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <GetCalendarEvent
          setConfirm={setConfirm}
          confirm={confirm}
          isVisible={setpopupVisible}
          id={eventid}
          token={token}
        />
      </Dialog>

      <Dialog
        open={createnew}
        className="createEventDialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor: "popup.titleHeaderColor",
            color: "popup.popupheadertextcolor",
            mb: 4,
          }}
        >
          {curLanguage.createNewEvent}
          <Button
            className="popupCloseButton"
            onClick={() => setCreateNewPop(false)}
            color="popupbutton"
            variant="contained"
            sx={{ backgroundColor: "secondaryColor.main", color: "white" }}
          >
            {" "}
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
          }}
        >
          <CreateEventContainer
            dates={datecal}
            close={setCreateNewPop}
            onEventUpdate={handleEventUpdated}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
export default Calendar;
