import React, { useContext, useEffect, useState } from "react";
import { Box, Card, Container, Grid, Typography } from "@mui/material";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";
import { languages } from "../../App";
import axios from "axios";
import StudentRecord from "./StudentRecord";
import AddAttendance from "./AddAttendance";
import UploadAttendance from "./UploadAttendance";
import DownloadAllAttendance from "./DownloadAllAttendance";
import "./Attendance.scss"
import MainBackBtn from "../../_Components/Common/BackBtn/MainBackBtn";

const Attendance = () => {
  let curLanguage = useContext(languages);
  const token = localStorage.getItem("token");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const financialYr = localStorage.getItem("financialYear");
  const [attendanceData, setAttendanceData] = useState(null);
  const [studentInfo, setStudentInfo] = useState([]);

  useEffect(() => {
    let studentsData = null;
    const fetchData = async () => {
      try {
        const students = JSON.parse(localStorage.getItem("students"));

        if (students == null) {
          const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getTeacherAssignedClassStudent?teacher_id=${userinfo[0]?.id}`;
          const response1 = await axios.get(baseURL);
          const classTeacherData = response1.data.filter(
            (val) => val.is_classteacher === 1
          );
            console.log(classTeacherData, "classTeacherData")
          const enroledURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_enrolled_users_info&moodlewsrestformat=json&courseid=${classTeacherData[0].courseid}`;
          console.log(classTeacherData[0].section, "classTeacherData")
          const response2 = await axios.get(enroledURL);
          const studentData = response2.data.filter(
            (val) =>
              val.department.toLowerCase() === "student" &&
              val.section === classTeacherData[0].section &&
              val.institution === userinfo[0].institution
          );
          setStudentInfo(studentData);
          console.log(studentData, "classTeacherData")
          studentsData = studentData;
        } else {
          setStudentInfo(students);
          studentsData = students;
        }
        // ========================================================================================

        const studentIds = studentsData?.map((data) => data.id);
        
        console.log(studentIds, "classTeacherData")
        const attendanceUrl = `${process.env.REACT_APP_nodewsPrefix}/viewAttendance`;
        const response = await axios.post(attendanceUrl, {
          student_id: studentIds,
          class_id: "",
          section_id: "",
          financial_year: financialYr,
          day: "",
          month: "",
        });
        console.log(response.data, "responsesss");

        const updatedAttendanceData = studentIds.reduce(
          (content, studentId) => {
            content[studentId] = response.data.attendance_data[studentId];
            return content;
          },
          {}
        );
        setAttendanceData(updatedAttendanceData);
        console.log(updatedAttendanceData, "updatedAttendanceData");
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    fetchData();
  }, []);

  const handleAttendance = async () => {
    try {
      const studentIds = studentInfo.map((data) => data.id);
      const attendanceUrl = `${process.env.REACT_APP_nodewsPrefix}/viewAttendance`;
      const response = await axios.post(attendanceUrl, {
        student_id: studentIds,
        class_id: "",
        section_id: "",
        financial_year: financialYr,
        day: "",
        month: "",
      });
      const updatedAttendanceData = studentIds.reduce((content, studentId) => {
        content[studentId] = response.data.attendance_data[studentId];
        return content;
      }, {});

      setAttendanceData(updatedAttendanceData);
      console.log("Attendance added and updated!");
    } catch (error) {
      console.error("Error adding attendance:", error);
    }
  };
  const dataPres = studentInfo.length > 0;
  return (
    <Container maxWidth="xl">
      <Card
        maxWidth="xl"
        sx={{
          p: 0,
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
        className="customCard"
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{ borderColor: "borderLine.main" }}
        >
          <Box className="titleHead">
            <MainBackBtn />
            <b>{curLanguage.attendance}</b>
          </Box>
          <Box>
            {dataPres && (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
                gap={2}
              >
                <AddAttendance
                  studentInfo={studentInfo}
                  attendanceinfo={attendanceData}
                  onAttendanceUpdate={handleAttendance}
                />
                <DownloadAllAttendance
                  studentInfo={studentInfo}
                  attendanceinfo={attendanceData}
                />
                <UploadAttendance onAttendanceUpdate={handleAttendance} />
              </Box>
            )}
          </Box>
        </Typography>

        <Box className="customCardContnet" p={2}>
          {dataPres && (
            <StudentRecord
              studentInfo={studentInfo}
              attendanceData={attendanceData}
            />
          )}
        </Box>
      </Card>
    </Container>
  );
};

export default Attendance;
